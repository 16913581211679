import React from "react"
import {NavLink} from "react-router-dom"
import {routes as Routing} from "../../services/RoutesHelper"
import i18n from "i18next"

export function UserPoints({user}) {
    return <div className="user-points">
        <NavLink to={Routing.history.path} title={i18n.t("game.history")}>
            <div className="content">
                <img
                    src={`/img/icon/${user.points > 0 ? "positif coin" : user.points < 0 ? "negatif coin" : "neutre coin"}.svg`}
                    alt="coin-icon"
                    width={40}
                />
                <div className="user-currentPoints">{user.points} {i18n.t("game.points")}</div>
            </div>
        </NavLink>
    </div>
}