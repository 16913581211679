import "./quizCorrectionDetails.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useTranslation} from "react-i18next"
import {Api, Constants} from "scg.common-library";
import {useEffect, useState} from "react";
import {QUESTION_TYPES} from "../../services/Constants";
import {useCurrentUser} from "../../hooks/customHooks";
import i18n from "i18next";
import Modal from "../general/Modal";
import QuizReport from "./QuizReport";

export default function CorrectionDetailsPage({...props}) {
    const {t} = useTranslation()
    const [histories, setHistories] = useState([])
    //eslint-disable-next-line
    useEffect(() => {getData()}, [])

    async function getData() {
        const response = await Api.history.getHistoriesForQuiz(props.quizId)
        if (response.status === Constants.HTTP_OK){
            setHistories(response.data)
        }
    }

    return <div className="correction-details-container">
        <div className="flex-start correction-details-back">
            <div style={{cursor: "pointer"}} onClick={() => {props.handleClose()}}>
                <FontAwesomeIcon icon="fa-solid fa-arrow-left" /> <span style={{paddingLeft: 10}}>{t("game.quizCorrectionDetails.back")}</span>
            </div>
        </div>
        <div className="correction-details-list" >
            {
                histories.map(element => <ElementHistory key={element.id} history={element}/>)
            }
        </div>
    </div>
}

function ElementHistory({history}) {
    const {t} = useTranslation()
    const allDisplay = (history.answersDisplayed === null) ? [] : history.answersDisplayed.split(",")
    let allAnswer = (history.answers === null) ? [] : history.answers.split(",")
    let indicationIconQuestion = "partiellement%20correcte.svg"
    let indicationTextQuestion = t("game.quizCorrectionDetails.partiallyCorrect")
    let indicationColorQuestion = "correction-details-indication-partially"
    if (!history.validated) {
        indicationIconQuestion = "question%20passée.svg"
        indicationTextQuestion = t("game.quizCorrectionDetails.pastQuestion")
        indicationColorQuestion = "correction-details-indication-past"
    } else {
        if (history.completionPercent === 100) {
            indicationIconQuestion = "correcte.svg"
            indicationTextQuestion = t("game.quizCorrectionDetails.correct")
            indicationColorQuestion = "correction-details-indication-correct"
        }
        if (history.completionPercent === 0) {
            indicationIconQuestion = "incorecte.svg"
            indicationTextQuestion = t("game.quizCorrectionDetails.incorrect")
            indicationColorQuestion = "correction-details-indication-incorrect"
        }
    }
    const coinIconQuestion = (history.answerPoints > 0) ? "positif%20coin.svg"
            : (history.answerPoints < 0) ? "negatif%20coin.svg"
                : "neutre%20coin.svg"
    const coinQuestion = ((history.answerPoints < 0) ? "" : "+") + history.answerPoints
    let answer = []
    if (history.question.questionType.shortName === QUESTION_TYPES.MCQ) {
        answer = history.question.mcqAnswers
    }
    if (history.question.questionType.shortName === QUESTION_TYPES.TrueFalse) {
        answer = history.question.truefalseAnswers
    }
    if (history.question.questionType.shortName === QUESTION_TYPES.Gaps) {
        answer = history.question.gapsAnswers
        allAnswer = JSON.parse(history.answers)
    }

    //eslint-disable-next-line
    const [loading, user] = useCurrentUser()
    
    let timeMax = 60
    if (user.hasOwnProperty("society")) {
        timeMax = user.society.parameter.timeLimitQuestion
    }
    const colorTimer = (history.responseTime > timeMax) ?
        "timer-red" : (history.responseTime < 10) ? "timer-green" : "timer-blue"
    
    const [quizReport, setQuizReport] = useState({
        showModalReport: false,
        reportReasonsList: []
    })
    // --------
    
    const openModal = () => {
        setQuizReport(prevState => ({...prevState, showModalReport: true}))
    }
    
    const closeModal = () => {
        setQuizReport(prevState => ({...prevState, showModalReport: false}))
    }
    useEffect(() => {
        const getAllReportReason = async () => {
            const response = await Api.reportReason.getReportsReason()
            if (response?.status === Constants.HTTP_OK) {
                setQuizReport(prevState => ({...prevState, reportReasonsList: response.data}))
            } 
        }
      getAllReportReason()
    }, [])
    
        
    return <>
        {/* Modal pour le report */}
        <Modal title={i18n.t("game.quiz_report.title_modal")}
                isShowing={quizReport.showModalReport}
            hide={() => closeModal()}
            >
            <QuizReport
                reportReason={quizReport.reportReasonsList}
                question={history.question.question}
                historyId={history.id}
                userId={user.id}
                category={history.category.name}
                hide={() => closeModal()}/>
        </Modal>

        <div style={{marginTop: 10}}>
            {history.category.name}
        </div>
        <div className="display-flex">
            <div className="display-flex w-75">
                <div className="correction-details-element">
                    <div className="correction-details-question v-align spaced-beetwen-contents-center ">
                        { [QUESTION_TYPES.Gaps].includes(history.question.questionType.shortName) ? <GapsResponse question={history.question.question} answers={answer} allAnswer={allAnswer} /> : history.question.question}
                        <FontAwesomeIcon
                            title={i18n.t("game.quiz_report.title_flag")}
                            icon="fa-regular fa-flag"
                            className="icon next"
                            onClick={() => openModal()}
                        />
                    </div>
                    
                    <div style={{paddingTop: 15}}>
                        {
                            [QUESTION_TYPES.MCQ,  QUESTION_TYPES.TrueFalse].includes(history.question.questionType.shortName) &&
                            answer.filter(element => allDisplay.includes(element.id.toString())).map(el => {
                                const text = (history.question.questionType.shortName === QUESTION_TYPES.MCQ)
                                            ? el.text : t(el.truefalseLabel.label)
                                return <LigneResponse key={el.id} allAnswer={allAnswer}
                                            id={el.id} correct={el.correct}
                                            text={text} />
                            })
                        }
                    </div>
                    { history.question.answerExplanatoryText &&
                        <div className="correction-details-explination">
                            {history.question.answerExplanatoryText}
                        </div>
                    }
                    <div style={{paddingTop: 15}}>
                        <div>
                            <div className="display-flex">
                                <div className="display-flex" style={{width: "40px"}}></div>
                                {
                                    [QUESTION_TYPES.MCQ,  QUESTION_TYPES.TrueFalse].includes(history.question.questionType.shortName) &&
                                    answer.filter(element => allDisplay.includes(element.id.toString())).filter(elem => elem.correct).map(el => {
                                        return <div key={`resp-${el.id}`} className="correction-details-real-answer">
                                            {(history.question.questionType.shortName === QUESTION_TYPES.TrueFalse)
                                                ? t(el.truefalseLabel.label) : el.text}
                                        </div>
                                    })
                                }
                                {
                                    [ QUESTION_TYPES.Gaps].includes(history.question.questionType.shortName) &&
                                    answer.map(el => {
                                        return <div key={`resp-${el.id}`} className="correction-details-real-answer">{el.text}</div>
                                    })
                                }
                                <div className={`correction-details-indication ${indicationColorQuestion}`}>
                                    <div className="correction-details-indication-icon">
                                        <img alt="icon" title="icon" width="25px"
                                             src={`/img/correction/${indicationIconQuestion}`}/>
                                    </div>
                                    <div className="correction-details-indication-text">
                                        {indicationTextQuestion}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="block-coin">
                <div className="block-coin-image">
                    <img alt="coin" title="coin" width="60px" src={`/img/correction/${coinIconQuestion}`}/>
                </div>
                <div className="block-coin-details">
                    <div className="correction-details-point-text">{t("game.quizCorrectionDetails.points")}</div>
                    <div className="correction-details-point-number">{coinQuestion} pts</div>
                    <div className="display-flex">
                        <div className="correction-details-point-text">{t("game.quizCorrectionDetails.responseTime")}</div>
                        <div className={`correction-details-point-timer ${colorTimer}`}><FontAwesomeIcon icon="fa-regular fa-clock" /> {history.responseTime} s</div>
                    </div>
                    <div className="correction-details-point-divider"></div>
                    <div className="display-flex" style={{minWidth: 225}}>
                        <div><img alt="bonus" title="bonus" width="18px"
                                  src="/img/correction/bonus.svg"/></div>
                        <div className="correction-details-point-text" style={{paddingLeft: 10, width: 130}}>{t("game.quizCorrectionDetails.bonus")}</div>
                        <div className="correction-details-point-bonus">+{history.bonusPoints} pts</div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

function LigneResponse({allAnswer, id, correct, text}){
    let image = null
    let color = "ligne-simple"
    if (allAnswer.includes(id.toString())) {
        image = correct ? <FontAwesomeIcon icon="fa-solid fa-check" /> : <FontAwesomeIcon icon="fa-solid fa-xmark" />
        color = correct ? "ligne-correct" : "ligne-false"
    }

    return <div style={{paddingBottom: 15}}>
        <div className="display-flex">
            <div className={`flex ${color}`} style={{width: "40px"}}>{image}</div>
            <div className={`correction-details-answer ${color}`}>
                {text}
            </div>
        </div>
    </div>
}

function GapsResponse({ question, allAnswer, answers}){
    let patterns = []
    let response = question
    if(allAnswer){
        allAnswer.forEach( answer => {
            const gaps = answers.filter( item => item.id === answer?.answer )[0]
            patterns.push({ pattern: `[[${answer?.position}]]`, text: gaps?.text, correct : gaps?.position === answer?.position })
        } )
    }else{
        answers.forEach( answer => {
            patterns.push({ pattern: `[[${answer?.position}]]`, text: answer.text, correct : false })
        } )
    }
    if(patterns.length){
        patterns.forEach( pattern => {
            response = response.replace(pattern.pattern, `<span class="gaps-item ${ pattern.correct ? 'gaps-correct' : 'gaps-not-correct' }">${pattern.text}</span>` )
        } )
    }
    return (
        <div className="wrap-gaps-response">
            <div dangerouslySetInnerHTML={{__html: response}} />
        </div>
    )
}
