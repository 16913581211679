import React from "react"
import {BackButton} from "../general/Button"
import {routes as Routing} from "../../services/RoutesHelper"
import {useTranslation} from "react-i18next"

export default function InformationCenter() {
    const {t} = useTranslation()

    return <div className="badges">
        <h1>Page des fiches d'information</h1>
        <BackButton to={Routing.game.path} title={t("game.changelog.back")} className="changelog-backButton"/>

    </div>
}