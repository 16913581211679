import { Scene } from 'phaser';
import { dataLoader } from '../services/dataloader';
import { EventBus } from '../services/EventBus';
import { GAME_WIDTH } from '../../../services/Constants';


export class GameOver extends Scene
{
    constructor() {
        super('GameOver');
        this.story = {};
        this.props = {
            storyStep: 0,
            handleClick : () => {}
        };
    } 

    init(data) {
        this.props = data;
    }   

    create ()
    {
        this.setupScene()
        // this.cameras.main.setBackgroundColor(0xff8351);
    }

    setupScene(){
        this.story = dataLoader.getStory(this.props.storyStep);
        if (!this.story) {
            console.error('Step data not found!');
            return;
        } 
        EventBus.emit('setStory', this.props.storyStep);

        // const bg = this.add.image(0, 0, 'background');

        this.add.text(GAME_WIDTH/2, 384, 'Game Over', {
            fontFamily: 'Arial Black', fontSize: 128, color: 'white',
            stroke: '#000000', strokeThickness: 8,
            //centered

        }).setOrigin(0.5).setDepth(100);

        EventBus.emit('current-scene-ready', this);

        // const imageName = this.story.image.replace(".png", "")
        // const sprite = this.add.sprite(0, 0, imageName).setOrigin(0, 0);
    }

    changeScene ()
    {
        this.scene.start('MainMenu');
    }
}
