import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { v4 as uuid } from 'uuid';
import i18n from 'i18next';
import './inputPasswordMui.css'

const InputPasswordMui = ({name, variant = "outlined", theme = "futurist", placeholder = i18n.t("activate.password.default"), handleChangePassword, passwordObj }) => {
    const [showPassword, setShowPassword] = useState(false);
    
    // Icon visibility
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };   

    return (
        <FormControl variant={variant}>
            <OutlinedInput
                id={`${uuid()}-${theme}-password`}
                name={name}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                placeholder={placeholder}
                className={`MuiPasswordInput-${theme}`}
                onChange={(e) => handleChangePassword(e)}
                value={passwordObj[name] && (passwordObj[name]["value"]? passwordObj[name]["value"] : "")}
                error={passwordObj[name] && passwordObj[name]["error"] ? true : false}
                size="small"
            />
            {(passwordObj[name] && passwordObj[name]['error']) && <FormHelperText error>{passwordObj[name]["helperText"]}</FormHelperText>}
        </FormControl>
    )
}

export default InputPasswordMui