import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { v4 as uuid } from 'uuid';
import InputAdornment from '@mui/material/InputAdornment';
import i18n from 'i18next';
import './inputTextMui.css'
import { Loading } from './Loading';


const InputTextMui = ({name, inputObj, placeholder = i18n.t("parameter.form.name"), variant = "outlined", theme = "futurist", handleChangeInput, handleSave, endAdornment, inputProps, title}) => {
    const [info, setInfo] = useState({
        [name]: ""
    })
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setInfo({ [name]: inputObj[name].value })
        setIsLoading(false)
        // eslint-disable-next-line
    }, [inputObj[name], name])

    
    const handleChange = (e) => {
        setInfo({ ...info, [name]: e.target.value })
    }

    const handleSubmit = (info) => {
        // Si même pseudo, on ne fait rien
        if (info[name] === inputObj[name].value) {
            return
        }
        
        handleSave(info, name)
        setIsLoading(true)
    }

    return (
        <TextField
            name={name}
            id={`${uuid()}-${theme}-password`}
            className='MuiTextInput-futurist'
            placeholder={placeholder}
            maxRows={1}
            variant={variant}
            onChange={(e) => handleChange(e)}
            onBlur={(e) => handleSubmit(info)}
            value={info[name]? info[name] : ""}
            onKeyDown={(e) => { e.key === 'Enter' && e.target.blur() }}
            error={inputObj[name].error}
            helperText={inputObj[name].helperText}
            inputProps={inputProps}
            InputProps={{
                endAdornment:
                    <>
                        {isLoading && <Loading/>}
                        {endAdornment && <InputAdornment position="start"> {endAdornment}</InputAdornment>}
                    </>
            }}
            size="small"
            title={title && title}
            required
        />
    )
}

export default InputTextMui