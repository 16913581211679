import React from "react"

class Level extends React.Component {
    render() {
        let stars = []
        for (let i = 1; i <= 4; i++) {
            if (i <= this.props.level.rank) {
                stars[i] = <img src="/img/icon/star.svg" alt="full star" className="level-star-full" key={i}/>
            } else {
                stars[i] = <img src="/img/icon/star.svg" alt="empty star" className="level-star-empty" key={i}/>
            }
        }
        return (
            <div className="flex-col align-end">
                <div id="level-stars">
                    {stars}
                </div>
                <span className="uppercase linear-color text-center level-name">{this.props.level.name}</span>
            </div>
        )
    }
}

export default Level