import { Scene } from 'phaser';
import { showDialogue, createButton, interactivePolygons } from '../services/sceneUtils';
import { dataLoader } from '../services/dataloader';
import { EventBus } from '../services/EventBus';


export class DialogueChallenge extends Scene {
    constructor() {
        super('DialogueChallenge');
        this.story = {};
        this.props = {
            storyStep: 0,
            handleClick : () => {}
        };
        this.sceneLaunched = false;
    }

    init(data) {
        this.props = data;
    }    

    preload() {
        this.story = dataLoader.getStory(this.props.storyStep);
    }

    create() {
        this.setupScene();
    }

    setupScene() {
        //Setup story
        if (!this.story) {
            console.error('Step data not found!');
            return;
        } 
        EventBus.emit('setStory', this.props.storyStep);
        showDialogue(this.story.description);

        // Left side image
        const imageName = this.story.image.replace(".png", "")
        const sprite = this.add.sprite(20, 0, imageName).setOrigin(0, 0);
        sprite.displayHeight = this.sys.canvas.height;


        // Polygones cliquables 
        const shapes = this.story.clickZones ? this.story.clickZones : [];
        if (shapes.length > 0 && Array.isArray(shapes)) interactivePolygons(this, sprite, shapes);

        // Right side buttons
        // this.createButtons(this.story.buttons);

        EventBus.emit('current-scene-ready', this); 
    }

    createButtons(buttons) {
        const centerX = this.sys.canvas.width / 2;
        // const centerY = this.sys.canvas.height / 2;

        const boxWidth = Math.min(600, this.sys.canvas.width * 0.4);
        const boxHeight = Math.min(300, this.sys.canvas.height * 0.5);
        const boxX = centerX;
        // const boxY = centerY - (boxHeight / 2);

        const buttonsContainer = this.add.container(boxX, 0);

        const background = this.add.rectangle(0, 0, boxWidth + (boxWidth * 0.05), boxHeight + (boxHeight * 0.1), 0x333333).setOrigin(0, 0);
        buttonsContainer.add(background);

        const helpText = this.add.text(boxWidth * 0.05, boxHeight * 0.05, "Bonjour, puis-je vous aider?", {
            fontSize: `${boxWidth * 0.04}px`,
            color: '#ffffff'
        });
        buttonsContainer.add(helpText);

        let buttonY = boxHeight * 0.2;
        const buttonPadding = boxHeight * 0.05;
        const buttonMaxWidth = boxWidth - (boxWidth * 0.1);

        buttons.forEach((el) => {
            const button = createButton(this, el.name, boxWidth * 0.05, buttonY, buttonMaxWidth)
                .on('pointerdown', () => this.handleClick(el.trigger))
                .on("pointerover", () => {
                    this.input.manager.canvas.style.cursor = 'pointer';
                })
                .on("pointerout", () => {
                    this.input.manager.canvas.style.cursor = 'default';
                })

            buttonsContainer.add(button);
            buttonY += button.height + buttonPadding;

        });
    }

    handleClick(action) {
        if (this.sceneLaunched) return; // Prevent multiple scenes from being launched

        if (this.props.handleClick) {
            this.props.handleClick(this, this.props.storyStep, action);
        }
    }
}
