import { Scene } from 'phaser';
import { showDialogue, interactivePolygons } from '../services/sceneUtils';
import { dataLoader } from '../services/dataloader';
import { EventBus } from '../services/EventBus';
import { GAME_WIDTH } from '../../../services/Constants';

export class ActionChallenge extends Scene {
    constructor() {
        super('ActionChallenge');
        this.story = {};
        this.props = {
            storyStep: 0,
            handleClick : () => {}
        };
        this.sceneLaunched = false;
    }
    
    init(props) {
        this.props = props;
    }

    preload() {
        this.story = dataLoader.getStory(this.props.storyStep);
    }

    create() {
        EventBus.on('data-loaded', this.setupScene, this);
    }
        
    setupScene() {        
        if (!this.story) {
            console.error('Step data not found!');
            return;
        } 
        EventBus.emit('setStory', this.props.storyStep);
        showDialogue(this.story.description);
        
        const imageName = this.story.image.replace(".png", "");
        const sprite = this.add.sprite(GAME_WIDTH / 2, 0, imageName).setOrigin(0.5, 0);
        sprite.displayHeight = this.sys.canvas.height;

        const shapes = this.story.clickZones ? this.story.clickZones : [];
        if (shapes.length > 0 && Array.isArray(shapes)) interactivePolygons(this, sprite, shapes);

        EventBus.emit('current-scene-ready', this);

        EventBus.on('button-click', this.handleClick, this);
    }

    handleClick(action) {
        if (this.sceneLaunched) return; // Prevent multiple scenes from being launched
        
        if (this.props.handleClick) {
            this.props.handleClick(this, this.props.storyStep, action);
        }
    }
}
