import { Scene } from 'phaser';
import { changeScene, stopScene, showButtons, showDialogue } from '../services/sceneUtils';

export class ChallengePreloader extends Scene {
    constructor() {
        super('ChallengePreloader');
        this.stories = {};
    }
    
    init(data) {
        // this.challengeDatas = data.challengeDatas;
        this.stories = data.challengeDatas.story;

        //  A simple progress bar. This is the outline of the bar.
        this.add.rectangle(512, 384, 468, 32).setStrokeStyle(1, 0xffffff);

        //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
        const bar = this.add.rectangle(512 - 230, 384, 4, 28, 0xffffff);

        //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
        this.load.on('progress', (progress) => {
            //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
            bar.width = 4 + (460 * progress);
        });
    }

    preload() {
        this.load.setPath('/challenges/challenge1');

        Object.keys(this.stories).forEach(key => {
            this.load.image(this.stories[key].image.replace(".png", ""), this.stories[key].image);
        });
    }

    create() {
        const handleClick = (scene, storyKey, action) => {
            const trigger = this.stories[storyKey].triggers[action];
            
            if (this.stories[storyKey].type === "action" && trigger.buttons?.length > 0) {
                showButtons();
            }
            if (this.stories[storyKey].type === "dialog" && trigger.buttons?.length > 0) {
                scene.createButtons(trigger.buttons);
            }

            showDialogue(trigger.message);
            if (trigger.nextScene) {
                scene.sceneLaunched = true;
                
                // S'il y a une prochaine scène, on la charge après 1s du clic
                let nextKey = storyKey + 1;
                if (trigger.nextScene === "GameOver") nextKey = this.stories[storyKey].gameOverKey; 
                if (trigger.nextScene === "Success") nextKey = this.stories[storyKey].successKey; 

                // Stop current scene and start next scene
                setTimeout(() => {
                    stopScene(scene);
                    changeScene(this, trigger.nextScene, { storyStep: nextKey, handleClick: handleClick });
                }, 1500);
            }
        }

        this.scene.start('ActionChallenge', { storyStep: 1, handleClick: handleClick });
    }
}
