import {useEffect, useState} from "react"
import {Api, Session} from "scg.common-library"
import {TextareaField} from "../general/Input"
import {ButtonPrimary} from "../general/Button"
import i18n from "i18next"
import "./QuizFeedBack.css"
import {Rate} from "../general/Rate"

export default function QuizFeedBack({
                                         quizId = 1,
                                         callbackSuccess = null,
                                         callbackFailed = null,
                                         callbackSkipping = null
                                     }) {
    const [understanding, setUnderstanding] = useState(0)
    const [difficulty, setDifficulty] = useState(0)
    const [feedback, setFeedback] = useState("")
    const [data, setData] = useState()
    useEffect(() => {
        fetch("/locales/fr-fr.json")
            .then(response => response.json())
            .then(json => setData(json))
    }, [])
    

    return <>
        <div className="side-to-side-rate">
            <Rate captionText={i18n.t("game.quiz_feedback.difficulty")}
                  defaultText={i18n.t("game.quiz_feedback.difficulty")}
                  callBackGettingValue={setDifficulty}
                  libelle={data?.game?.quiz_feedback?.difficulties_rate}
            />
            <Rate captionText={i18n.t("game.quiz_feedback.understanding")}
                  defaultText={i18n.t("game.quiz_feedback.understanding")}
                  callBackGettingValue={setUnderstanding}
                  libelle={data?.game?.quiz_feedback?.understanding_rate}
            />
        </div>
        <TextareaField name="feedback" placeHolder={i18n.t("game.quiz_feedback.feedback_input")}
                       onChange={(e) => setFeedback(e.target.value)}> </TextareaField>
        <div className="flex-sb">
            <ButtonPrimary onClick={() => callbackSkipping()}>{i18n.t("game.quiz_feedback.ignore")}</ButtonPrimary>
            <ButtonPrimary onClick={() => save()}>{i18n.t("game.quiz_feedback.send")}</ButtonPrimary>
        </div>
    </>


    async function save() {
        const response = await Api.feedback.createQuizFeedback(understanding, difficulty, feedback, quizId, Session.getJwtToken())
        if (response.status === 201) {
            callbackSuccess()
        } else {
            callbackFailed()
        }
    }
}