import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import './iconCheckboxMui.css'

const IconCheckboxMui = ({ icon, checkedIcon=<CheckOutlinedIcon/>, name, checked=false, onChange, theme="futurist", width=20, height=20}) => {
  const handleChange = (e) => {
    const info = { [e.target.name]: e.target.checked }
    onChange(info)
  }
  
  return (
    <Checkbox
      name={name}
      icon={icon ? icon : <svg className='MuiSvgIcon-root'></svg>}
      checkedIcon={checkedIcon}
      className={`MuiCheckbox-${theme}`}
      sx={{ '& .MuiSvgIcon-root': { width: width, height: height} }}
      checked={checked}
      onChange={(e) => handleChange(e)}
      disableRipple
    />
  )
}

export default IconCheckboxMui