import React from 'react'
import './userIcon.css'
import { Tooltip } from '@mui/material';
import i18n from 'i18next';

const UserIcon = ({iconObj={'link': ""}, locked=false, active=false, handleChange, width=120, height=126}) => {

  return (
    <Tooltip
            title={locked && <div style={{ fontSize: '0.82rem' }}>{i18n.t("profile.generalMenu.iconLocked_tooltip")}</div>}
            placement='top'
        >
      <div
        className={`userIcon-container ${locked ? 'locked' : ''} ${active ? 'active' : ''}`}
        style={{ width: width, height: height }}
        onClick={() => (!locked && !active) && handleChange(iconObj)}
      >
        <img src={iconObj ? iconObj.link : ''} alt={"icon"} className="userIcon-img" />

        <div className="ligne-top"></div>
        <div className="ligne-bottom"></div>

        <div className='userIcon-containerbg'></div>
        {locked && <div className='userIcon-container-lock'></div>} 
      </div>
    </Tooltip>
  )
}

export default UserIcon