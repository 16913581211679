/* ------------------------ QUESTIONS TYPES -------------------------- */

export const QUESTION_TYPES = {
    MCQ: 'mcq',
    Gaps: 'gaps',
    TrueFalse: 'trueFalse'
}
// challenges constants
export const GAME_WIDTH = 1280;
export const GAME_HEIGHT = 768;
