import {Constants} from "scg.common-library"
import {toast} from "react-toastify"
import moment from "moment";

/**
 *
 * @param length {number}
 * @return {string}
 */
function generateUUID(length = 5) {
    const uuid = crypto.randomUUID()
    return uuid.slice(uuid.length - 5)
}

/**
 *
 * @return {string}
 */
function getCurrentTheme() {
    return localStorage.getItem(`${Constants.PREFIX_LOCAL_STORAGE}-user-theme`) ?? "dark"
}

/**
 *
 * @param theme{string}
 */
function setCurrentTheme(theme = "dark") {
    localStorage.setItem(`${Constants.PREFIX_LOCAL_STORAGE}-user-theme`, theme)
    document.querySelector("body").dataset.theme = theme
}

async function getLocalTranslations(langage = "FR") {
    return await Promise.all([
        fetch(`/locales/${Constants.TRANSLATIONS_FILES.FR}`),
        fetch(`/locales/${Constants.TRANSLATIONS_FILES.EN}`)
    ]).then(responses => {
        // Get a JSON object from each of the responses
        return Promise.all(responses.map(response => response.json()))
    }).then(data => {
        return {
            FR: data[0],
            EN: data[1]
        }
    }).catch(error => {
        console.log(error)
        return {
            FR: {},
            EN: {}
        }
    })
}

/**
 *
 * @param {string} message - The message to display
 * @param {('success'|'error'|'warning'|'info')} type - Type of toast to display
 * @param {?int | false} delay - Time in milliseconds after which the message will fade out
 * @param {('light'|'dark'|'colored')} theme - The toast theme to used (dark by default)
 * @param {string} position - see toast.POSITION to see the allowed values
 */
function displayMessage(message, type = "success", delay = null, theme = "dark", position = toast.POSITION.TOP_CENTER) {
    delay = delay ?? 2000
    switch (type) {
        case "success":
            toast.success(message, getToastOptions(delay, theme, position))
            break
        case "error":
            toast.error(message, getToastOptions(delay, theme, position))
            break
        case "warning":
            toast.warning(message, getToastOptions(delay, theme, position))
            break
        default:
            toast.info(message, getToastOptions(delay, theme, position))
            break
    }
}

function getToastOptions(delay = 2000, theme = "dark", position = toast.POSITION.TOP_CENTER) {
    return {
        position: position,
        autoClose: delay,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme
    }
}


function isValidResponse(responseObject) {
    let response = false
    if (responseObject.hasOwnProperty("data") && typeof responseObject.data === "object") {
        response = responseObject.data
        if (responseObject.data.hasOwnProperty("@type") && responseObject.data["@type"] === "hydra:Collection") {
            response = responseObject.data["hydra:member"]
        }
    }
    return response
}

/**
 * Check licence of organisation
 *
 * @param {object} user
 * @return boolean
 */
function isActivateLicence(user) {
    let response = true

    if (user.hasOwnProperty("society")) {
        if (user.roles.includes(Constants.ROLE_SUPER_ADMIN)) {
            return response
        }
        if (moment() > moment(user.society.subscriptionExpirationDate)) {
            response = false
        }
    }

    return response
}

/**
 * Import moment locale dynamically
 *
 * @param {string} userLanguage
 * @return void
 */
function importLocale(userLanguage){
    let locale = 'fr'
    switch (userLanguage){
        case 'en':
            locale = 'en-gb'
            break
        case 'zh':
            locale = 'zh-cn'
            break
        case 'jp':
            locale = 'ja'
            break
        default:
            locale = userLanguage
    }

    import(`moment/locale/${locale}`)
}

/**
 * Format date by user locale
 *
 * @param {string} entryDate
 * @param {object} i18n
 * @return string
 */
function formatDateByUserLocale(entryDate, i18n = null){
    let userLanguage = 'en'
    if( i18n && i18n.hasOwnProperty('resolvedLanguage') ){
         userLanguage = i18n.resolvedLanguage
        importLocale(userLanguage)
    }
    let date = moment(entryDate).locale(userLanguage)
    return date.format('LL').replace(':', 'h')
}

/**
 * Format date by user locale
 *
 * @param {string} entryTime
 * @param {object} i18n
 * @return string
 */
function formatTimeByUserLocale(entryTime, i18n = null){
    let userLanguage = 'en'
    if( i18n && i18n.hasOwnProperty('resolvedLanguage') ){
        userLanguage = i18n.resolvedLanguage
        importLocale(userLanguage)
    }
    let date = moment(entryTime).locale(userLanguage)
    return date.format('LT')
}

const exportedFunctions = {
    generateUUID,
    displayMessage,
    getCurrentTheme,
    setCurrentTheme,
    getLocalTranslations,
    getToastOptions,
    isValidResponse,
    isActivateLicence,
    formatDateByUserLocale,
    formatTimeByUserLocale
}
export default exportedFunctions