import React from 'react'
import { useTranslation } from 'react-i18next';
import './profileIcon.css'

const ProfileIcon = ({iconObj, frame, handleClick, width=120, height=126}) => {
  const { t } = useTranslation(); 

  return (
    <div
      className={`profileIcon-container`}
      style={{ width: width, height: height }}
      onClick={() => handleClick && handleClick(iconObj)}
    >
      <img src={iconObj ? iconObj.link : ''} alt={"icon"} title={t("game.openMenu")} className="profileIcon-img" />

      {/* Si pas de cadre, on highlight le cadre par défaut */}
      {!frame &&(
        <>
          <div className="ligne-top"></div>
          <div className="ligne-bottom"></div>
        </>
      )}
    </div>
  )
}

export default ProfileIcon