import ProfileNavbar from "../../profile/ProfileNavbar"
import {Outlet} from "react-router-dom"
import "./userProfile.css"

export default function UserProfile() {
    return (
        <>
            <ProfileNavbar/>
            <div className="profile-container">
                <div className="profile-block">
                    <Outlet/>
                </div>
            </div>
        </>
    )

}
