import React from "react"

export function UserAvatar({user}) {
    const avatarLink = user?.avatar?.link

    return <div className="user-avatar-full">
        <div className="avatar-wrapper">
            <img src={avatarLink} alt="user avatar"/>
        </div>
        <style>
            {`div.user-avatar-full div.avatar-wrapper::after {
                 background: url(${avatarLink}) no-repeat center center;
            }`}
        </style>
    </div>
}