

import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    routes as Routing,
    setRouteParameters
} from "../../services/RoutesHelper"
import { Api, Session, Constants } from "scg.common-library"
import { TimerClock, TimerText } from "./Timer"
import Level from "./Level"
import { Loading, LoadingOverlay } from "../general/Loading"
import Helper from "../../services/Helper"
import i18n from "i18next"
import Modal from "../general/Modal"

import "./quiz.css"
import QuizReport from "./QuizReport"
import QuizzGaps from "./QuizzGaps"
import { QUESTION_TYPES } from "../../services/Constants"

class QuizHomePage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedAnswers: new Set(),
            user: null,
            quizId: null,
            numberOfQuestions: 3,
            questionCounter: 1,
            timeLeft: this.props?.parametersObject?.timeLeft ?? 60,
            question: {
                id: null,
                category: "",
                text: "",
                levelId: null,
                levelShortName: "bronze"
            },
            answers: [],
            currentHistoryId: null,
            previousHistoriesIds: [],
            //previousQuestions: [],
            //selectedAnswers: [],
            submittedAnswers: [],
            quizFinished: false,
            loading: true,
            modalReport: false,
            reportReason: [],
            stopQuiz: false,
            canAccessPage: true,
            isResponseSelected: false,
            answerExplication: null,
            points: 0,
            isValidate: false,
            idTrueAnswer: "",
            typeQuestion: null,
            gapsAnswer: null
        }
        this.startQuiz = this.startQuiz.bind(this)
        this.nextQuestion = this.nextQuestion.bind(this)
        this.toggleSelectAnswer = this.toggleSelectAnswer.bind(this)
        this.answersData = []
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.getAllReportReason = this.getAllReportReason.bind(this)
        this.validateResponse = this.validateResponse.bind(this);
        this.divRef = React.createRef();
    }

    async getAllReportReason() {
        const response = await Api.reportReason.getReportsReason()
        if (response?.status === Constants.HTTP_OK) {
            this.setState({
                reportReason: response.data
            })
        }
    }


    updateAnswerData = (newAnswerData, ifResponseSelected) => {
        this.setState({ gapsAnswer: newAnswerData, isResponseSelected: ifResponseSelected });
    }

    shuffleArray(array) {
        const newArray = array.slice();

        for (let i = newArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
        }

        return newArray;
    }
    async responseBasedOnQuestion(questionDetails) {
        const typeQuestion = await this.getTypeQuestion(questionDetails)
        if (typeQuestion.questionType.shortName === QUESTION_TYPES.TrueFalse) {
            const responsesWithText = questionDetails.answers.map(response => {
                return {
                    ...response,
                    text: i18n.t(response.text)
                };
            });
            return responsesWithText
        }
        return this.shuffleArray(questionDetails.answers);
    }

    async getTypeQuestion(question) {
        const responseTypeQuestion = await Api.question.getQuestion(
            question.question.id
        )
        const typeQuestion = Helper.isValidResponse(responseTypeQuestion)
        this.setState({ typeQuestion: typeQuestion })
        return typeQuestion;
    }

    async checkTypeAnswer(questionDetails) {
        if (questionDetails.question) {
            const typeQuestion = await this.getTypeQuestion(questionDetails);
            if (typeQuestion.questionType.shortName) {
                const shortName = typeQuestion.questionType.shortName;
                return shortName;
            }
        }
    }

    async startQuiz(canAccessPage, savedQuizId) {
        const user = Session.getSessionUser()
        // Si l'user n'a pas le droit de jouer, on enregistre le quiz comme étant abandonné et on le redirige vers la page d'accueil
        if (!canAccessPage) {
            if (!savedQuizId) {
                const responseQuiz = await Api.quiz.startNewQuiz(user.id);
                const quiz = Helper.isValidResponse(responseQuiz);
                localStorage.setItem('quizId', quiz.id);
                Api.quiz.getQuizSubmittedResume(quiz.id, this.answersData, 0);
            } else {
                Api.quiz.getQuizSubmittedResume(savedQuizId, this.answersData, 0);
            }

            this.setState({ loading: false });
            return;
        }

        const responseQuiz = await Api.quiz.startNewQuiz(user.id)

        if (responseQuiz.status === Constants.HTTP_OK) {
            const quiz = Helper.isValidResponse(responseQuiz)

            if (quiz.canStart) {
                const firstQuestionData = {
                    isFirstQuestion: true,
                    previousHistoriesIds: [],
                    previousHistoryId: null
                }

                const responseFirstQuestion = await Api.quiz.getNextQuestion(
                    quiz.id,
                    firstQuestionData
                )
                const firstQuestion = Helper.isValidResponse(responseFirstQuestion)
                this.setState({
                    user: user,
                    numberOfQuestions: quiz.numberOfQuestions,
                    quizId: quiz.id,
                    question: firstQuestion.question,
                    answers: await this.responseBasedOnQuestion(firstQuestion),
                    currentHistoryId: firstQuestion.currentHistoryId,
                    previousHistoriesIds: firstQuestion.previousHistoriesIds,
                    loading: false,
                    outOfTime: false,
                    typeQuestion: await this.checkTypeAnswer(firstQuestion)
                })

                localStorage.setItem('quizId', quiz.id);
                Api.history.startTimer(firstQuestion.currentHistoryId).then()
            } else {
                this.setState({
                    stopQuiz: true,
                    loading: false,
                    numberOfQuestions: quiz.numberOfQuestions
                })
            }
        }
    }

    addClassToSelectedAnswers(isCorrect) {
        const selectedAnswers = document.querySelectorAll('.answer.selected');

        selectedAnswers.forEach(answer => {
            answer.classList.add(isCorrect);
        });
    }

    async validateResponse() {
        this.setState({ isValidate: true })
        const response = await Api.history
            .endTimer(this.state.currentHistoryId)
            .then()

        const gapsAnswer = (this.state.typeQuestion === QUESTION_TYPES.Gaps) ?
            this.state.gapsAnswer.map((answer, index) => {
                return {
                    position: index + 1,
                    answer: (answer) ? answer.id : null,
                };
            }) : null

        const currentSubmittedAnswers = {
            historyId: this.state.currentHistoryId,
            submittedAnswers: (this.state.typeQuestion === QUESTION_TYPES.Gaps) ? Array.from(gapsAnswer) : Array.from(this.state.selectedAnswers)
        };

        this.answersData.push({
            historyId: this.state.currentHistoryId,
            submittedAnswers: (this.state.typeQuestion === QUESTION_TYPES.Gaps) ? Array.from(gapsAnswer) : Array.from(this.state.selectedAnswers)
        });


        this.setState({
            submittedAnswers: this.state.submittedAnswers.concat(currentSubmittedAnswers)
        });

        if (response.status === Constants.HTTP_CREATED) {
            this.setState({ outOfTime: true })
            const lastHistoryResponse = await Api.history.saveQuestionAndDetails(
                currentSubmittedAnswers.historyId,
                currentSubmittedAnswers.submittedAnswers,
                Session.getJwtToken()
            );
            if (this.state.typeQuestion !== QUESTION_TYPES.Gaps) {
                this.addClassToSelectedAnswers(lastHistoryResponse.data.result.correct ? "true" : "false")
                if (lastHistoryResponse.data.result.correctAnswers[0]) {
                    this.setState({
                        answerExplication: lastHistoryResponse.data.result.correctAnswers[0].explanation,
                        idTrueAnswer: lastHistoryResponse.data.result.correctAnswers[0].answer_id
                    });
                    // for(){}
                    // Utilisation de la référence pour accéder à l'élément div
                    const divWithTooltip = this.divRef.current;
                    if (divWithTooltip && divWithTooltip.id === lastHistoryResponse.data.result.correctAnswers[0].answer_id) {
                        divWithTooltip.classList.add("selected");
                        divWithTooltip.classList.add("true");
                    }
                }
            }
            this.setState({ points: lastHistoryResponse.data.result.points })
        }
    }


    async nextQuestion() {
        this.setState({
            isValidate: false,
            isResponseSelected: false,
            answerExplication: null
        })
        if (this.state.questionCounter === this.state.numberOfQuestions) {
            const response = await Api.history
                .endTimer(this.state.currentHistoryId)
                .then()

            if (response.status === 201) {
                const responseQuizResume = await Api.quiz.getQuizSubmittedResume(this.state.quizId, this.state.submittedAnswers, 1)
                if (responseQuizResume.status === Constants.HTTP_OK) {
                    this.setState({
                        quizFinished: true,
                        loading: false,
                        typeQuestion: await this.checkTypeAnswer(response)
                    })
                }
            }

        } else {
            const nextQuestionData = {
                isFirstQuestion: false,
                previousHistoriesIds: this.state.previousHistoriesIds,
                previousHistoryId: this.state.currentHistoryId
            }
            const response = await Api.quiz.getNextQuestion(
                this.state.quizId,
                nextQuestionData
            )
            const data = Helper.isValidResponse(response)
            this.setState({ loading: true })

            this.state.selectedAnswers.clear()
            this.setState({
                typeQuestion: await this.checkTypeAnswer(data),
                question: data.question,
                answers: await this.responseBasedOnQuestion(data),
                currentHistoryId: data.currentHistoryId,
                previousHistoriesIds: data.previousHistoriesIds,
                questionCounter: this.state.questionCounter + 1,
                loading: false,
                outOfTime: false
            })
            Api.history.startTimer(data.currentHistoryId).then()
        }
    }


    componentDidMount() {
        const checkIfCanAccessPage = async () => {
            const response = await Api.user.getUser(
                Session.getSessionUser().id,
                true
            )
            if (response.status === 200) {
                const lastQuiz =
                    Date.parse(response.data.user.lastQuiz) +
                    response.data.user.society.parameter.timeBetweenQuiz * 60000
                const now = Date.now()
                if (lastQuiz > now || !this.props?.parametersObject?.fromNormalStep) {
                    //redirection vers le menu du jeu car il ne peut pas faire un nouveau quiz
                    this.setState({ canAccessPage: false })
                    return false
                }
            } else {
                //redirection vers le menu du jeu car c'est ambigu vis à vis de l'echec
                this.setState({ canAccessPage: false })
                return false
            }
            return true
        }
        //on lance la sécurisation du quiz si le chemin d'accès est appellé à la vollée sans suivre les etapes ou si l'user a actualisé la page
        // if (this.props?.parametersObject?.fromNormalStep !== true) {
        const savedQuizId = localStorage.getItem('quizId');

        checkIfCanAccessPage()
            .then((canAccessPage) => {
                this.startQuiz(canAccessPage, savedQuizId);

                if (canAccessPage) {
                    this.getAllReportReason();
                }
            })

        // } else {
        // this.startQuiz().then()
        // this.getAllReportReason()
        // }

        window.addEventListener("beforeunload", this.beforeCloseAction, false)
        //TODO: abdoul - ne stoppe même pas le quiz et fait que ça génère 2 lignes de rapport donc j'ai commenté pour l'instant
        // window.addEventListener(
        //     "visibilitychange",
        //     this.sendStopQuizReportAfterChangeFocus,
        //     false
        // )
        // window.addEventListener("unload", this.sendStopQuizReportAfterClose, false)
    }

    componentWillUnmount() {
        // window.removeEventListener("beforeunload", this.beforeCloseAction, false)
        // // window.removeEventListener(
        // //     "visibilitychange",
        // //     this.sendStopQuizReportAfterChangeFocus,
        // //     false
        // // )
        // window.removeEventListener(
        //     "unload",
        //     this.sendStopQuizReportAfterClose,
        //     false
        // )
    }


    toggleSelectAnswer(e) {
        e.preventDefault();
        const selectedId = e.target.id.toString();
        if (this.state.outOfTime) {
            Helper.displayMessage(i18n.t("game.quiz_home.out_of_time_restriction"), "error");
            return;
        }

        this.setState(prevState => {
            const updatedSelectedAnswers = new Set(prevState.selectedAnswers);

            if (updatedSelectedAnswers.has(selectedId)) {
                updatedSelectedAnswers.delete(selectedId);
            } else {
                updatedSelectedAnswers.add(selectedId);
            }
            this.setState({ isResponseSelected: updatedSelectedAnswers.size >= 1 });
            return { selectedAnswers: updatedSelectedAnswers };
        });
    }

    correctStyleSelectAnswer() {

    }

    sendStopQuizReportAfterClose = () => {
        Api.quiz.getQuizSubmittedResume(this.state.quizId, this.answersData, 0)
    }

    // sendStopQuizReportAfterChangeFocus = () => {
    //     if (document.hidden) {
    //         Api.quiz.getQuizSubmittedResume(this.state.quizId, this.answersData, 0)
    //     }
    // }

    beforeCloseAction = (e) => {
        e.preventDefault()
        e.returnValue = ""
    }

    openModal() {
        this.setState({
            modalReport: true
        })
    }

    closeModal() {
        this.setState({
            modalReport: false
        })
    }

    render() {
        if (this.state.quizFinished) {
            return (
                <Navigate
                    state={{ answers: this.state.submittedAnswers }}
                    to={setRouteParameters(Routing.quiz_correction.path, {
                        id: this.state.quizId
                    })}
                    replace
                />
            )
        }
        if (this.state.loading) {
            const isFirstLoading = this.state.questionCounter === 1 && this.state.submittedAnswers.length === 0
            return isFirstLoading ? <LoadingOverlay /> : <Loading />
        }

        if (this.state.stopQuiz) {
            return <StopQuiz
                stopQuiz={this.state.stopQuiz}
                description={this.state.numberOfQuestions === 0 ?
                    i18n.t("game.quiz_home.information_not_available") :
                    i18n.t("game.quiz_home.not_enough_quiz")}
            />
        }

        if (!this.state.canAccessPage) {
            return <Navigate to={Routing.game.path} />
        }

        const currentQuestionLevelRank = this.state.question.levelRank
        const currentQuestionLevelName = i18n.t("game.quiz_level." + this.state.question.levelShortName)

        return (
            <>
                <Modal title={i18n.t("game.quiz_report.title_modal")}
                    isShowing={this.state.modalReport}
                    hide={this.closeModal}>
                    <QuizReport
                        reportReason={this.state.reportReason}
                        question={this.state.question.text}
                        historyId={this.state.currentHistoryId}
                        userId={this.state.user.id}
                        category={this.state.question.category}
                        hide={this.closeModal} />
                </Modal>
                <div className="content-quiz-container">
                    <div className="quiz-container">
                        <div className="grid-col-3 col v-align h-align">
                            {this.state.outOfTime ? (
                                <div className="time-label-finish">
                                    {/* <p>
                          <span className="">{i18n.t("quiz.finished")}</span>
                      </p> */}
                                </div>
                            ) : (
                                <>
                                    <TimerClock
                                        timeLeft={this.state.timeLeft}
                                        width={200}
                                        height={200}
                                        lineWidth={5}
                                        timeElapsedHandler={() => {
                                            Helper.displayMessage(i18n.t("game.quiz_home.out_of_time"), "error")
                                            this.setState({ outOfTime: true, isValidate: true })
                                        }}
                                    />
                                    <div className="time-label">
                                        <p>
                                            <TimerText timeLeft={this.state.timeLeft} />{" "}
                                            <span className="">s</span>
                                        </p>

                                    </div>
                                </>
                            )}

                            <div className="category-wrapper">
                                <h2 className="category">{this.state.question.category}</h2>
                            </div>
                            <Level level={{ rank: currentQuestionLevelRank, name: currentQuestionLevelName }} />
                        </div>
                        <div className="flex-row row center v-align quizz-question">
                            <span className="question"> {(this.state.typeQuestion === QUESTION_TYPES.Gaps) ? this.state.question.title : this.state.question.text}</span>
                            <img src="/img/quizz/flag.svg" alt="icon-flag" className="icon-flag" onClick={()=>{this.setState({modalReport:true})}}/>
                        </div>
                        <div className="question-counter text-center">
                            <div className="counter">
                                {this.state.questionCounter}/{this.state.numberOfQuestions}
                            </div>
                        </div>

                        <div className="quiz-point">
                            {(this.state.typeQuestion === QUESTION_TYPES.Gaps) ? (
                                <QuizzGaps
                                    initialItems={this.shuffleArray(this.state.answers)}
                                    text={this.state.question.text}
                                    updateAnswerData={this.updateAnswerData}
                                    isResponseSelected={this.state.isResponseSelected}
                                    isDraggable={this.state.isValidate}
                                />
                            )
                                : (
                                    <div className={`${(this.state.typeQuestion === QUESTION_TYPES.MCQ) ? "grid-col-2 " : ""} col v-align answers-container ${(this.state.typeQuestion === QUESTION_TYPES.TrueFalse) ? "trueFalse" : ""}`}>
                                        {this.state.answers.map((answer, index) => (
                                            <div
                                                key={index}
                                                id={answer.id}
                                                className={`flex-row v-align h-align row answer ${this.state.selectedAnswers.has(answer.id.toString()) ? "selected" : ""} ${answer.id === this.state.idTrueAnswer ? "true" : ""}`}
                                                onClick={this.toggleSelectAnswer}
                                                data-tooltip={answer.text}
                                                ref={this.divRef}
                                            >
                                                {answer.isImageOnly ? (
                                                    <img
                                                        className="answer-image"
                                                        src={answer.imageLink}
                                                        alt={answer.text}
                                                    />
                                                ) : (
                                                    answer.text
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}

                            {this.state.outOfTime &&
                                <div className="points">
                                    <img src={`/img/icon/${(this.state.points > 0 ? "positif coin.svg" : (this.state.points < 0) ? "negatif coin.svg" : "neutre coin.svg")}`} alt="coin" className="icon-coin" />
                                    <div className="content-points">
                                        <div className="text-points">
                                            <div className="title">{i18n.t("quiz.points")}</div>
                                            <div className="point-value">{(this.state.points >= 0) ? "+" : "-"}{this.state.points} {i18n.t("quiz.pts")}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        {this.state.outOfTime ? (
                            <div className="explication">
                                {this.state.answerExplication &&
                                    <div className="answer-explication">
                                        <div className="text-answer-explication">{this.state.answerExplication}</div>
                                    </div>
                                }
                                <div className="button-quizz" onClick={this.nextQuestion} >
                                {i18n.t("quiz.next")}
                                </div>
                            </div>
                        ) : (
                            <>
                                {this.state.isResponseSelected ? (
                                    <div className="action-quizz" onClick={this.validateResponse} >{i18n.t("quiz.validate")}
                                        <FontAwesomeIcon icon="fa-solid fa-check" className="icon action" />
                                    </div>
                                ) : (
                                    <div className="action-quizz" onClick={this.validateResponse} >
                                        <span>{i18n.t("quiz.pass")}</span>
                                        <FontAwesomeIcon icon="fa-solid fa-xmark" className="icon action" />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </>
        )
    }
}

function StopQuiz({ stopQuiz, description }) {
    const [redirection, setRedirection] = useState(false)
    const backHome = () => {
        setRedirection(true)
    }
    if (redirection) {

        return <Navigate to={setRouteParameters(Routing.game.path, {})} />
    }
    return <>
        <Modal title={i18n.t("game.quiz_home.not_available")}
            isShowing={stopQuiz}
            hide={backHome}>
            <div className="text-center">
                <div>
                    <div style={{ width: 390, display: "inline-grid", marginBottom: 50 }}>
                        {description}
                    </div>
                </div>
                <div>
                    <input
                        type='button'
                        className='btn default'
                        value={i18n.t("game.quiz_home.back_home")}
                        onClick={backHome}
                    />
                </div>
            </div>
        </Modal>
    </>
}

export default QuizHomePage
