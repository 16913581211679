import React, {useState, useEffect} from "react"
import GameMenu from "./GameMenu"
import {UserLevel} from "./UserLevel"
import {UserPoints} from "./UserPoints"
import {UserParameters} from "./UserParameters"
import moment from "moment"
import {TimingButton} from "../general/Button"
import {UserAvatar} from "./UserAvatar"
import {NavLink} from "react-router-dom"
import {routes as Routing} from "../../services/RoutesHelper"
import {useCurrentUser} from "../../hooks/customHooks"
import {LoadingOverlay} from "../general/Loading"
import i18n from "i18next"
import {Constants, Api} from "scg.common-library"
import Helper from "../../services/Helper"
import ParameterModal from "./ParamterModal"

import "./gameHome.css"

export default function GameHomePage() {
    const [loading, user] = useCurrentUser()
    const isActiveLicence = Helper.isActivateLicence(user)
    const {quizTimeLeft, challengeTimeLeft} = getTimeLeft(user)
    const [openParameterModal, setOpenParamerModal] = useState(false)
    const [tip, setTip] = useState(null)
    const [maintenanceMessage, setMaintenanceMessage] = useState(null); 


    /*setting up route titles when component is loaded completly*/
    useEffect(() => {
        Routing.quiz_info.title = i18n.t("game.quiz_info.title")
        Routing.quiz_progress.title = i18n.t("game.quiz_progress.title")
        if (localStorage.getItem("quizId")) {
            localStorage.removeItem("quizId")
        }

        const getTip = async () => {
            const response = await Api.tip.getRandomTip()
            if (response.status === Constants.HTTP_OK) {
                const text = response.data.text
                setTip(typeof text === "object" && text !== null ? JSON.stringify(text) : text || null)
            }
        }

        getTip().then()

        const getMaintenanceMessage = async () => {
            const response = await Api.message.getActiveMessage();
            if (response.status === Constants.HTTP_OK) {
                const message = response.data;
                const startDate = moment.utc(message.startDate);
                const endDate = moment.utc(message.endDate);
                const currentMoment = moment.utc();

                if (currentMoment.isBetween(startDate, endDate)) {
                    setMaintenanceMessage(message);
                } else {
                    console.error("Le message de maintenance est programmé pour une date ultérieure ou est expiré.");
                }
            }
        };
        getMaintenanceMessage().then();
    }, [])


    if (loading) {
        return <LoadingOverlay/>
    }

    return <>
        <section className="game-container">
            {!isActiveLicence && <div className="license-user">{i18n.t("error.expiredLicence")}</div>}
            <div className="game-header">
                <UserLevel user={user} isActiveLicence={isActiveLicence}/>
                <div className="logo">
                    <a href={`${Routing.game.path}`} title={i18n.t("home.title")}>
                        <img src="/logo192.png" title={Constants.DOCUMENT_TITLE_FRONTOFFICE}
                             alt={`${i18n.t("home.title")} logo`}/>
                    </a>
                </div>
                <div className="game-header-end">
                    <UserPoints user={user}/>
                    <UserParameters
                        onClick={() => setOpenParamerModal(true)}
                    />
                </div>
            </div>

            <div className="game-main-content">
                <div className="game-menu">
                    <NavLink to={quizTimeLeft === 0 && isActiveLicence ? Routing.quiz_progress.path : "#"}
                             title={i18n.t("game.quiz")}
                             state={{"fromNormalStep": true, "quizTimeLeft": quizTimeLeft}}>
                        <TimingButton eventType={i18n.t("game.event.quiz")}
                                      classPrefix="quiz"
                                      iconClassName="fa-clipboard-question"
                                      buttonName="quizButton"
                                      nextEventTime={quizTimeLeft}
                                      forceBlockButton={!isActiveLicence}
                        />
                    </NavLink>
                    <div className="space"></div>
                    <NavLink to={(challengeTimeLeft === 0 && isActiveLicence) ? Routing.challenge_info.path : "#"}
                             title={i18n.t("game.challenge")}>
                        <TimingButton eventType={i18n.t("game.event.challenge")}
                                      classPrefix="challenge"
                                      iconClassName="fa-clipboard-question"
                                      buttonName="challengeButton"
                                      nextEventTime={challengeTimeLeft}
                                      forceBlockButton={!isActiveLicence}
                        />
                    </NavLink>
                    <GameMenu isActiveLicence={isActiveLicence}/>
                </div>

                <div className="game-user-avatar">
                    <UserAvatar user={user}/>
                </div>
                <div className="game-mode-menu">
                    {tip &&
                        <>
                            <div className="tip-label">{i18n.t("game.tip")}</div>
                            <div className="tip-text"><span>{tip}</span></div>
                        </>
                    }
                </div>
            </div>
        </section>
        {maintenanceMessage && ( 
                <div className="user-notification">
                    <div className="content">
                        <div className="title">{maintenanceMessage.title.toUpperCase()}</div>
                        <div className="text">{maintenanceMessage.description}</div>
                    </div>
                </div>
            )}
        <ParameterModal
            user={user}
            show={openParameterModal}
            onClose={() => setOpenParamerModal(false)}
        />
    </>
}

/**
 * Calcul le temps restant à attendre avant le prochain quiz/challenge
 *
 * @param {object} user
 * @return {{quizTimeLeft: number, challengeTimeLeft: number}}
 */
function getTimeLeft(user) {
    //Todo: Récupérer ces infos depuis les paramètres de la société à laquelle est rattaché l'utilisateur
    let timeBetweenChallenge = 1440
    let timeBetweenQuiz = 1440

    let result = {quizTimeLeft: timeBetweenQuiz, challengeTimeLeft: timeBetweenChallenge}
    if (user === null) {
        return result
    }

    if (user.hasOwnProperty("society")) {
        if (user.society.parameter.timeBetweenChallenge !== undefined) {
            timeBetweenChallenge = user.society.parameter.timeBetweenChallenge
        }
        if (user.society.parameter.timeBetweenQuiz !== undefined) {
            timeBetweenQuiz = user.society.parameter.timeBetweenQuiz
        }
    }

    if (user.hasOwnProperty("lastChallenge")) {
        const lastChallenge = moment(user.lastChallenge)
        const duration = moment.duration(moment().diff(lastChallenge))
        const durationAsMinutes = (duration.asMinutes()).toFixed(2)
        let timeLeft = timeBetweenChallenge - durationAsMinutes
        result.challengeTimeLeft = (timeLeft > 0) ? timeLeft : 0
    }
    if (user.hasOwnProperty("lastQuiz")) {
        const lastQuiz = moment(user.lastQuiz)
        const duration = moment.duration(moment().diff(lastQuiz))
        const durationAsMinutes = (duration.asMinutes()).toFixed(2)
        let timeLeft = timeBetweenQuiz - durationAsMinutes
        result.quizTimeLeft = (timeLeft > 0) ? timeLeft : 0
    }

    return result
}