import React, {useContext} from "react";
import {UserProfileContext} from "../../context/UserProfileContext";
import ProfileIcon from "../profile/general/ProfileIcon";
import ProfileTitle from "../profile/general/ProfileTitle";
import './userProfile.css'

export default function UserProfile() {
    const {user} = useContext(UserProfileContext)
    return(
        <div className='user-profile'>
            <div className='user-icon'>
                <ProfileIcon iconObj={user?.icon} width={100} height={105} active/>
                <span className="user-name">
                    <span className="user-pseudo">{user?.pseudo}</span>
                </span>
            </div>
            {user?.title && <ProfileTitle titleObj={user?.title} style={{ padding: "5px 8px", fontSize: 12 }} />}
        </div>
    )
}