import { NavLink } from "react-router-dom"
import { routes as Routing } from "../../services/RoutesHelper"
import i18n from "i18next"

export default function GameMenu({ ...props }) {
    return <>
        <NavLink to={Routing.shop.path} title={i18n.t("game.home.shop")}>
            <div className="min-label-menu">
                <span>{i18n.t("game.home.shop")}</span> <img src="img/icon/recompenses.svg" alt="icon-shop" />
            </div>
        </NavLink>

        <NavLink to={(props.isActiveLicence) ? Routing.scoreboard.path : "#"} title={i18n.t("game.home.scoreboard")}>
            <div className="min-label-menu">
                <span>{i18n.t("game.home.scoreboard")}</span> <img src="img/icon/scoreboard.svg" alt="icon-scoreboard" />
            </div>
        </NavLink>

        <NavLink to={Routing.badge.path} title={i18n.t("game.home.badges")}>
            <div className="min-label-menu">
                <span>{i18n.t("game.home.badges")}</span> <img src="img/icon/badges.svg" alt="icon-badge" />
            </div>
        </NavLink>
    </>
}
