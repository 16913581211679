import { NavLink, useLocation } from "react-router-dom";
import { routes as Routing } from "../../services/RoutesHelper";
import { useTranslation } from "react-i18next";
import ProfileIcon from "./general/ProfileIcon";
import ProfileTitle from "./general/ProfileTitle";
import { useContext } from "react";
import { UserProfileContext } from "../../context/UserProfileContext";
import "./profileNavbar.css";

export default function ProfileNavbar() {
    const {t} = useTranslation()
    const {user} = useContext(UserProfileContext)

    const location = useLocation()

    const navLinkClassName = ({isPending, pathname}) => {
        return isPending || location.pathname.includes(pathname) ? "active" : ""
    }

    return (
        <div className="profile-head">
            <NavLink to={Routing.game.path} style={{textDecoration: "none", paddingTop: 20}}>
                <div className="profile-btn-back">
                    <div className="txt-back">{t("profile.back")}</div>
                    <div className="ligne-top"></div>
                </div>
            </NavLink>

            <div className="profile-menu">
                <div className="ligne-top"></div>
                <div className="ligne-bottom"></div>
                <div className="flex profile-links">
                    <NavLink to={Routing.profile_general_infos.path}
                            className={`w-25 ${navLinkClassName({pathname: Routing.profile_general.path})}`}>
                        <span>{t("profile.menu.general")}</span>
                    </NavLink>
                    <NavLink to={Routing.profile_avatar.path}
                            className={`w-25 ${navLinkClassName({pathname: Routing.profile_avatar.path})}`}>
                        <span>{t("profile.menu.avatar")}</span>
                    </NavLink>
                    <NavLink to={Routing.history.path}
                            className={`w-25 ${navLinkClassName({pathname: Routing.history.path})}`}>
                        <span>{t("profile.menu.history")}</span>
                    </NavLink>
                    <NavLink to={Routing.profile_statistic.path}
                            className={`w-25 ${navLinkClassName({pathname: Routing.profile_statistic.path})}`}>
                        <span>{t("profile.menu.statistic")}</span>
                    </NavLink>
                </div>
            </div>

            <div className="flex" style={{gap: "2rem", alignItems: "center", justifySelf: "end"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    height: "100%"
                }}>
                    <span className="profile-avatar-username">
                        <span className="profile-avatar-pseudo">{user?.pseudo}</span>
                        <span>#{user?.tag}</span>
                    </span>
                    {user?.title && <ProfileTitle titleObj={user?.title} style={{width: 'fit-content', padding: '5px 10px'} } />}
                </div>
                <ProfileIcon iconObj={user?.icon} width={100} height={105} active/>
            </div>
        </div>
    );
}
