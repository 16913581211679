import React, { useEffect, useRef, useState } from 'react';
import UserIcon from './UserIcon';
import { IconButton } from '@mui/material';
import { Api, Session } from 'scg.common-library';
import Helper from '../../../../services/Helper';
import i18n from 'i18next';
import { Loading } from '../../../general/Loading';
import { useContext } from 'react';
import { UserProfileContext } from '../../../../context/UserProfileContext';
import './profileGIcons.css';

const ProfileGIcons = () => {
  const { user, setUser, loading } = useContext(UserProfileContext)
  const [isLoading, setIsLoading] = useState(true)
  const [iconsList, setIconsList] = useState([])
  const [scrollPos, setScrollPos] = useState(0);
  const [iconsListHeight, setIconsListHeight] = useState(0);
  const scrollVelocity = 225

  const scrollableDivRef = useRef(null);

  useEffect(() => {
    async function getIconsList() {
      const result = await Api.icon.getIconUser()
      if (result.status === 200) {
          setIsLoading(false)
          setIconsList([...result.data])
        }
    }
    getIconsList()
   }, [])
  
  // Handle Scroll ----------------------------------------------------------------------
  useEffect(() => {
    const currentDiv = scrollableDivRef.current;

    const handleScroll = () => {
      const newScrollPos = Math.max(0, Math.min(currentDiv.scrollTop, iconsListHeight));
      setScrollPos(newScrollPos);
    };

    if (currentDiv) {
      setIconsListHeight(currentDiv.scrollHeight - currentDiv.clientHeight);
      currentDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentDiv) {
        currentDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, [iconsListHeight, iconsList.length]);

  const scrollBy = (value) => {
    if (scrollableDivRef.current) {
      let newScrollPos = scrollPos + value;
      newScrollPos = Math.max(0, Math.min(newScrollPos, iconsListHeight));
      setScrollPos(newScrollPos);
      scrollableDivRef.current.scrollTop = newScrollPos;
    }
  };

  // HandleChange icon ----------------------------------------------------------------------
  const handleChangeIcon = async (icon) => {
    const userInfo = { iconId: icon.id, roles: user?.roles };
    const response = await Api.user.updateUser(user?.id, userInfo, Session.getJwtAndRefreshToken())
		const result = Helper.isValidResponse(response)

		if (result) {
      setUser({ ...user, icon: { ...icon } })
			Helper.displayMessage(i18n.t("parameter.form.updated_success"))
		} else {
			//TODO: afficher un message d'erreur à l'utilisateur avec la raison précise de l'echec
			Helper.displayMessage(i18n.t("error.generic"), 'error')
		}
  };

  return (
    <div className="profileGIcon-container">
      {(loading || isLoading) && <Loading />}
      
      <div ref={scrollableDivRef} className="icons-list">
        {iconsList.map((icon, index) => ( 
          <UserIcon 
            key={index} 
            iconObj={icon} 
            handleChange={handleChangeIcon} 
            active={user?.icon && (icon.id === user?.icon.id)} 
            locked={!icon || !icon.unlocked} 
            width={125} 
            height={125} 
          />
        ))}
      </div>

      <div className="profileGIcon-scrollButtons">
        <IconButton className={`profileGIcon-scrollButton scrollButtonTop ${scrollPos < 10 ? 'disabled' : ''}`} aria-label="scroll-top" size="small" onClick={() => scrollPos > 10 && scrollBy(scrollVelocity * -1)}>
          <img src="/img/profile/icons/arrow-cybergame.svg" alt="arrow-buttonup" />
        </IconButton>
        <IconButton className={`profileGIcon-scrollButton scrollButtonBottom ${Math.round(scrollPos) + 10 >= Math.round(iconsListHeight) ? 'disabled' : ''}`} aria-label="scroll-bottom" size="small" onClick={() => Math.round(scrollPos) + 10 < Math.round(iconsListHeight) && scrollBy(scrollVelocity)}>
          <img src="/img/profile/icons/arrow-cybergame.svg" alt="arrow-buttondown" />
        </IconButton>
      </div>
    </div>
  );
};

export default ProfileGIcons;
