import "./history.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useEffect, useState} from "react";
import {Api, Constants, Session} from "scg.common-library";
import {useTranslation} from "react-i18next";
import moment from "moment";
import ProfileMenu from "../ProfileNavbar";
import Helper from '../../../services/Helper'

export default function HistoryPage() {
    const user = Session.getSessionUser()
    const perPage = 10
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [quizs, setQuizs] = useState([])
    const [filter, setFilter] = useState("all")
    const changePage = (definitionChoise) => {
        setPage(prevState => {
            let newPage = prevState
            if (definitionChoise === "up") {
                newPage = prevState + 1
            }
            if (definitionChoise === "down") {
                newPage = prevState - 1
            }
            return newPage
        })
    }
    const changeFilter = (myFilter) => {
        setFilter(myFilter)
        setPage(1)
    }
    //eslint-disable-next-line
    useEffect(() => {getData()}, [])
    //eslint-disable-next-line
    useEffect(() => {getData()}, [page, filter])
    return <>
        <ProfileMenu active="history"/>
        <div className="history-container">
            <div className="history-block">
                <Entete changeFilter={changeFilter}/>
                <div style={{minHeight: 630}}>
                    {
                        quizs.map(element => <Content key={element.id} quiz={element}/>)
                    }
                </div>
                <div className="flex-end">
                    <Pagination page={page} perPage={perPage} changePage={changePage} total={total}/>
                </div>
            </div>
        </div>
    </>

    async function getData() {
        const response = await Api.quiz.getQuizForUser(user.id, page, perPage, {history_filter: filter})
        if (response.status === Constants.HTTP_OK){
            setQuizs(response.data)
        }

        const responseTotal = await Api.quiz.getQuizUserCount(user.id, {history_filter: filter})
        if (responseTotal.status === Constants.HTTP_OK){
            setTotal(responseTotal.data.count)
        }
    }
}

function Content({quiz}){
    let seconds = 0
    for (const history of quiz.histories) {
        seconds += history.responseTime
    }
    const coinIconQuestion = (quiz.points > 0) ? "positif%20coin.svg"
        : (quiz.points < 0) ? "negatif.svg"
            : "neutre%20coin.svg"
    const coinPoints = ((quiz.points < 0) ? "" : "+") + quiz.points
    const colorPoints = (quiz.points < 0) ? "point-neg" : "point-pos"
    const duration = moment.duration(seconds, 'seconds')
    //const date = moment(quiz.date)
    const {t, i18n} = useTranslation()
    const dateQuiz = Helper.formatDateByUserLocale(quiz.date, i18n)
    const timeQuiz = Helper.formatTimeByUserLocale(quiz.date, i18n)
    return <>
        <div className="history-content flex-start history-ligne">
            <div className="w-20 flex-start">
                <div style={{padding: "0px 13px"}}><img alt="quiz" title="quiz" width="35px" src="/img/history/Quizz%20icon.svg" /></div>
                <div className="first-colonne">{t("history.content.quiz")}</div>
            </div>
            <div className="w-20">
                <div className="time">{dateQuiz}</div>
                <div className="time-second">
                    <FontAwesomeIcon icon="fa fa-circle" aria-hidden="true" width={5} />
                    <span style={{paddingLeft: 5}}>{timeQuiz}</span>
                </div>
            </div>
            <div className="w-20">{`${duration.minutes()}m ${duration.seconds()}s`}</div>
            <div className="w-20">
                {(quiz.abandoned) ? <Abandoned />:<CompletionQuiz quiz={quiz}/>}
            </div>
            <div className="w-20 flex-start">
                <div style={{paddingRight: 20}}>
                    <img alt="coin" title="coin" width="30px" src={`/img/correction/${coinIconQuestion}`}/>
                </div>
                <div className={`point ${colorPoints}`}>{coinPoints} pts</div>
            </div>
        </div>
        <div className="history-divider"></div>
    </>

}

function CompletionQuiz({quiz}){
    const {t} = useTranslation()
    const widthTotal = 130
    const widthStatus = Math.round((quiz.correctCount * widthTotal) / quiz.histories.length)
    const percent = Math.round((quiz.correctCount * 100) / quiz.histories.length)
    const ellipse = (percent < 50) ? "Red%20Ellipse.svg" :
        (percent < 80) ? "Bleu%20Ellipse.svg" : "Green%20Ellipse.svg"
    const progress = (percent < 50) ? "progress-div-red" :
        (percent < 80) ? "progress-div" : "progress-div-green"
    return <>
        <div className="percent-number">{quiz.correctCount}/{quiz.histories.length} {t("history.content.trueAnswers")}</div>
        <div className="progress">
            <div className="flex-start">
                <img alt="fd-white" title="fd-white" width={widthTotal} height={4} src="/img/history/Completion/BG%20rectangle.svg"/>
            </div>
            <div className="flex-start progress-status">
                <div style={{width: widthStatus}} className={progress}></div>
                <img style={{marginLeft: "-5px"}} alt="fd-white" title="fd-white" width={8} src={`/img/history/Completion/${ellipse}`}/>
            </div>
        </div>
    </>
}

function Abandoned() {
    const {t} = useTranslation()
    return <div className="flex-start">
        <div style={{paddingRight: 10}}><img alt="x" title="x" width="15px" src="/img/history/Icon%20red%20abandon.svg" /></div>
        <div className="percent-number">{t("history.content.abandoned")}</div>
    </div>
}

function Entete({changeFilter}) {
    const {t} = useTranslation()
    const [menu, setMenu] = useState(false)
    const [textMenu, setTextMenu] = useState("mode")
    const handleFilter = (element) => {
        switch (element){
            case "quiz":
                setTextMenu("quiz")
                break;
            case "challenge":
                setTextMenu("challenge")
                break;
            case "all":
                setTextMenu("mode")
                break;
            default:
                setTextMenu("mode")
        }
        changeFilter(element)
        setMenu(false)
    }
    return <div  style={{position: "relative"}}>
        <div className="history-entete flex-start">
            <div className="th">
                <div style={{paddingLeft: 13}} className="flex-start">
                    <div className="w-45">{t("history.head."+textMenu)}</div>
                    <FontAwesomeIcon onClick={() => setMenu(prevState => !prevState)} icon="fa fa-chevron-down" style={{fontSize: 25, cursor: "pointer"}}/>
                </div>
            </div>
            <div className="th">{t("history.head.date")}</div>
            <div className="th">{t("history.head.duration")}</div>
            <div className="th">{t("history.head.percent")}</div>
            <div className="th">{t("history.head.points")}</div>
        </div>
        { menu && <div className="history-menu-mode">
            <div className="menu-item" onClick={() => handleFilter("quiz")}>
                {t("history.head.quiz")}
                <div className="block-2"></div>
            </div>
            <div className="menu-item" onClick={() => handleFilter("challenge")}>
                {t("history.head.challenge")}
                <div className="block-2"></div>
            </div>
            <div className="menu-item" onClick={() => handleFilter("mode")}>
                {t("history.head.all")}
                <div className="block-2"></div>
            </div>
        </div>}
    </div>
}

function Pagination({page, perPage, changePage, total}) {
    const maxPage = (total / perPage)
    const disableMin = (page > 1) ? "" : "icon-disabled"
    const disableMax = (page < maxPage) ? "" : "icon-disabled"
    return <div className="history-pagination">
        <div className={`icon flex ${disableMin}`}>
        <FontAwesomeIcon className="icon-element" icon="fa fa-chevron-left" onClick={() => {if (page > 1) changePage("down")}}/>
        </div>
        <div className="page flex">{page}</div>
        <div className={`icon flex ${disableMax}`}>
            <FontAwesomeIcon className="icon-element" icon="fa fa-chevron-right" onClick={() => {if (page < maxPage) changePage("up")}}/>
        </div>
    </div>
}
