import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { routes as Routing } from "../../services/RoutesHelper";
import { useTranslation } from 'react-i18next';
import './challengeInformation.css'


const ChallengeInformation = () => {
  const { t } = useTranslation();

  return (
    <div className='challengeInfo-head'>
      <NavLink to={Routing.game.path} style={{ textDecoration: "none", paddingTop: 20 }}>
        <div className="challenge-btn-back">
            <div className="txt-back">{t("profile.back")}</div>
            <div className="ligne-top"></div>
        </div>
      </NavLink>
      <div className='challengeInfo-container'>
          <Link to={Routing.challenge.path} title={t("game.quiz_info.start")} className="btn-challengeInfo">
              <span>Challenge 1: Implantation d'un malware</span>
          </Link>
          <Link to={Routing.challenge.path} title={t("game.quiz_info.start")} className="btn-challengeInfo">
              <span>Challenge 2</span>
          </Link>
      </div>
    </div>
  )
}

export default ChallengeInformation