import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import React from "react"
import {Api, Session} from "scg.common-library"
import {ButtonPrimary} from "../general/Button"
import i18n from "i18next"

export default class LanguageParameters extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: this.props.user,
            currentLanguageId: this.props.user.language.id,
            edition: false
        }
        this.languages = this.props.languages
        this.handleSave = this.handleSave.bind(this)
    }

    componentDidMount() {
    }

    async handleSave() {
        const [currentLanguage] = this.languages.filter((lang) => lang.id === Number(this.state.currentLanguageId))
        const user = this.state.user
        user.currentLanguage = currentLanguage
        user.language = this.state.currentLanguageId
        this.props.toggleLoad()
        const response = await Api.user.updateUser(user.id, user, Session.getJwtAndRefreshToken())
        if (response.status === 200) {
            await i18n.changeLanguage(this.state.user.currentLanguage.code.split("-")[0]);
            this.props.toggleLoad()
        } else {
            console.log("une erreur est survenue")
            this.props.toggleLoad()
        }
    }

    render() {
        const languageOptions = this.props.languageOptions
        return <>
            <h2><FontAwesomeIcon icon="fa-solid fa-language"/> {i18n.t("parameter.language")}</h2>
            {!this.state.edition
                ? <>
                    {this.state.user.language.name} : {this.state.user.language.code}
                    <ButtonPrimary type="button" name="loginButton" disabled={this.props.disabled} onClick={(e) => {
                        this.setState({edition: true})

                        this.props.enable()
                    }}>
                        <FontAwesomeIcon icon="fa-regular fa-pen-to-square"/> {i18n.t("parameter.editLanguage")}
                    </ButtonPrimary>
                </>
                : <>
                    <select value={this.state.currentLanguageId}
                            onChange={(e) => this.setState({currentLanguageId: e.target.value})}>{languageOptions}</select>
                    <ButtonPrimary type="button" name="loginButton" onClick={this.handleSave}>
                        <FontAwesomeIcon icon="fa-regular fa-pen-to-square"/> {i18n.t("parameter.save")}
                    </ButtonPrimary>
                    <ButtonPrimary type="button" name="loginButton" onClick={(e) => {
                        this.setState({edition: false})
                        this.props.onCancel()
                    }}>
                        <FontAwesomeIcon icon="fa-regular fa-pen-to-square"/> {i18n.t("parameter.cancel")}
                    </ButtonPrimary>
                </>
            }
        </>

    }
}