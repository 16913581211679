import {useEffect, useState} from "react"

/* ********************************* HOOKS ********************************* */

// listen to key down/up and remove listener on unmount
const useInputEvent = () => {
    const [key, setKey] = useState(null)
    useEffect(() => {
        const keyDownHandler = ({code}) => setKey(code)
        const keyUpHandler = () => setKey(null)
        global.addEventListener("keydown", keyDownHandler)
        global.addEventListener("keyup", keyUpHandler)
        return () => {
            global.removeEventListener("keydown", keyDownHandler)
            global.removeEventListener("keyup", keyUpHandler)
        }
    }, [])
    return key
}

// check if the key entered correspond to the secretCode (passed in props)
export const useSecretCode = secretCode => {
    const [count, setCount] = useState(0)
    const [success, setSuccess] = useState(false)
    const key = useInputEvent()

    useEffect(() => {
        // exit if the key is up (null)
        if (key == null) return
        // reset count if the key does NOT match the next key from the code
        if (key !== secretCode[count]) {
            setCount(0)
            return
        }
        // increase the count by 1 if it does
        setCount(state => state + 1)
        // Set success to true if this is the final kay
        if (count + 1 === secretCode.length) {
            setSuccess(true)
        }
        //eslint-disable-next-line
    }, [key])

    return success
}


export const useKonamiCode = () => {
    const konamiCode = ["ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown", "ArrowLeft", "ArrowRight", "ArrowLeft", "ArrowRight", "KeyB", "KeyA"]
    return useSecretCode(konamiCode)
}