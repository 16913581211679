import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { NavLink} from "react-router-dom"
import {ButtonPrimary} from "../general/Button"
import {routes as Routing} from "../../services/RoutesHelper"
import i18n from "i18next"

export default function CreditsPage() {
    return <>
        <h1> Crédits</h1>
        <NavLink to={Routing.game.path} /*className="nav-item" title="Connexion" onClick={handleLoginClick}*/>
            <ButtonPrimary type="button" name="loginButton">
                <FontAwesomeIcon icon="fas fa-right-to-bracket"/> {i18n.t("component.button.back")}
            </ButtonPrimary>
        </NavLink>
    </>

}