import React, { useEffect } from 'react'
import UserProfile from "../general/UserProfile"
import { Link, useNavigate } from 'react-router-dom'
import PageTitle from "../general/PageTtitle"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import {routes as Routing} from "../../services/RoutesHelper"
import "./quizInformation.css"

const Information = ({ location }) => {
    
    const {t} = useTranslation()

    return (
        <div className="informations">
            <PageTitle>{t("game.quiz_info.title")}</PageTitle>
            <div className="wrap-informations">
                <div className="information-item info-question">
                    <div className="inner-info">
                        <p>{t("game.quiz_info.questions", {"number": location?.state?.questionNumber})}</p>
                    </div>
                </div>
                <div className="information-item info-delay">
                    <div className="inner-info">
                        <p>{t("game.quiz_info.delay", {"delay": location?.state?.delay})}</p>
                    </div>
                </div>
                <div className="information-item info-rule">
                    <div className="inner-info">
                        <p>{t("game.quiz_info.rule")}</p>
                    </div>
                </div>
                <div className="information-item info-abort">
                    <div className="inner-info">
                        <p>{t("game.quiz_info.abort")}</p>
                    </div>
                </div>
                <div className="btn-start-quiz">
                    <Link to={Routing.quiz.path} title={t("game.quiz_info.start")} className="quiz_start" state={{fromNormalStep:true,timeLeft: location?.state?.delay}}>
                        <span>{t("game.quiz_info.start")}</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default function QuizInformation() {

    const {t} = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    document.title = t("game.quiz_info.title")

    useEffect(() => {
        if (!location?.state?.fromNormalStep || location?.state?.quizTimeLeft > 0) {
            navigate(Routing.game.path);
        }
    }, [location, navigate])

    return (
        <div className='quiz-information-container'>
            <UserProfile />
            <Information location={location} />
        </div>
    )

}