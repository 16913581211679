import React, {useContext, useEffect, useState} from "react"
import UserTitle from "./UserTitle"
import {Api, Session} from "scg.common-library"
import Helper from "../../../../services/Helper"
import i18n from "i18next"
import ProfileIcon from "../ProfileIcon"
import ProfileTitle from "../ProfileTitle"
import {Loading} from "../../../general/Loading"
import { UserProfileContext } from "../../../../context/UserProfileContext"
import "./profileGTitles.css"

const ProfileGFrameTitles = () => {
    const { user, setUser, loading } = useContext(UserProfileContext)
    const [isLoading, setIsLoading] = useState(true)
    const [titlesList, setTitlesList] = useState([])

    const handleChangeTitle = async (title, active) => { // TODO: abdoul - update session
        const userInfo = {titleId: !active ? title.id : null, roles: user.roles}
        const response = await Api.user.updateUser(user.id, userInfo, Session.getJwtAndRefreshToken())
        const result = Helper.isValidResponse(response)

        if (result) {
            setUser({ ...user, title: (!active ? { ...title } : null) })
            Helper.displayMessage(i18n.t("parameter.form.updated_success"))
        } else {
            //TODO: afficher un message d'erreur à l'utilisateur avec la raison précise de l'echec
            Helper.displayMessage(i18n.t("error.generic"), "error")
        }
    }

    useEffect(() => {
        async function getTitlesList() {
            const result = await Api.title.getTitleUser()
            if (result.status === 200) {
                setIsLoading(false)
                setTitlesList([...result.data])
            }
        }

        getTitlesList()
    }, [])


    return <>
        {
            (loading || isLoading) ?
                <Loading/> :

                <div className="profileGTitles-container">
                    <div className="profileGTitles-left">
                        <div className="subtitle">Titre</div>
                        <div className="profileGTitles-titlesList">
                            {titlesList.map((title, index) => (
                                <UserTitle
                                    key={index}
                                    titleObj={title}
                                    active={user?.title && title.id === user?.title.id}
                                    handleChange={handleChangeTitle}
                                />
                            ))}
                        </div>
                    </div>

                    <div className="profileGTitles-separator"></div>

                    <div className="profileGTitles-right">
                        <ProfileIcon iconObj={user?.icon} width={220} height={230} active/>
                        {user?.title && <ProfileTitle titleObj={user?.title} handleChange={handleChangeTitle} style={{fontSize: 20, lineHeight: "29px"}}/>}
                    </div>
                </div>
        }
    </>
}

export default ProfileGFrameTitles
