import _ from "lodash"

export const routes = {
    app_home: {path: "/", title: "route.home"},
    app_login: {path: "/security/login", title: "route.login"},
    app_logout: {path: "/logout", title: "route.logout"},
    app_activate: {path: "/security/activate", title: "route.activate"},
    game: {path: "/game", title: "route.game"},
    profile: {path: "/game/profile/", title: "route.profile"},
    profile_general: {path: "/game/profile/general", title: "route.profile"},
    profile_avatar: {path: "/game/profile/avatar", title: "route.avatar"},
    profile_history: {path: "/game/profile/history", title: "route.history"},
    profile_statistic: {path: "/game/profile/statistic", title: "route.statistic"},
    profile_general_infos: {path: "/game/profile/general/infos", title: "route.profile"},
    profile_general_icons: {path: "/game/profile/general/icons", title: "route.profile"},
    profile_general_frames: {path: "/game/profile/general/frames", title: "route.profile"},
    profile_general_titles: {path: "/game/profile/general/titles", title: "route.profile"},
    information_center: {path: "/game/information_center", title: "route.information"},
    parameters: {path: "/game/parameters", title: "route.parameters"}, // TODO A supprimer quand c'est validé
    history: {path: "/game/history", title: "route.history"}, // TODO A supprimer  ???
    quiz: {path: "/game/quiz", title: "route.quiz"},
    quiz_correction: {path: "/game/quiz/:id/correction", title: "route.quizCorrection"},
    quiz_correction_details: {path: "/game/quiz/:id/correction-details", title: "route.quizCorrectionDetails"},
    quiz_progress: {path: "/game/quiz/progress", title: "route.progress"},
    quiz_info: {path: "/game/quiz/info", title: "route.quiz.info"},
    challenge_info: {path: "/game/challenge/info", title: "route.challenge_info"},
    challenge: {path: "/game/challenge", title: "route.challenge"},
    shop: {path: "/game/shop", title: "route.shop"},
    badge: {path: "/game/badge", title: "route.badge"},
    scoreboard: {path: "/game/scoreboard", title: "route.scoreboard"},
    changelog: {path: "/game/changelog", title: "route.changelog"},
    mentions: {path: "/game/mentions-legales", title: "route.mentions"},
    credits: {path: "/game/credits", title: "route.credits"},
    politique: {path: "/game/politique-de-confidentialite", title: "route.politique"}
}

/**
 * Return a route string url with parameters
 *
 * @param route {string} - the original route with param
 * @param params {object}
 * @return {string} - the formatted route
 */
export function setRouteParameters(route, params) {
    for (const [key, value] of Object.entries(params)) {
        route = route.replace(`:${key}`, value)
    }
    return route
}

/**
 *
 * @param path
 * @return {boolean}
 */
export function isGameRoute(path) {
    return path.startsWith("/game") || path.startsWith("/security")
}

/**
 *
 * @param path
 * @return {boolean}
 */
export function hasSecondaryBackground(path) {
    const authorizedRoutes = [
        routes.app_login.path,
        routes.app_activate.path,
        routes.profile.path,
        routes.history.path,
        routes.profile_general.path,
        routes.profile_avatar.path,
        routes.profile_history.path,
        routes.profile_statistic.path,
        routes.profile_general_infos.path,
        routes.profile_general_icons.path,
        routes.profile_general_frames.path,
        routes.profile_general_titles.path,
        routes.quiz.path,
        routes.quiz_correction.path,
        routes.quiz_progress.path,
        routes.quiz_info.path,
        routes.quiz_correction.path,
        routes.information_center
    ]
    return _.find(authorizedRoutes, r => r === path) !== undefined
}
