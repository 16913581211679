import { EventBus } from './EventBus';

class DataLoader {
    constructor() {
        this.data = {};
    }

    load(jsonFile) {
        fetch(jsonFile)
            .then(response => response.json())
            .then(data => {
                this.setDatas(data)
                
            })
            .catch(error => console.error('Error loading data:', error));
    }

    getStory(stepNumber) {
        return this.data.story[stepNumber];
    }

    getStories() {
        return this.data.story;
    }

    getDatas() {
        return this.data;
    }
    setDatas(data) {
        this.data = data;
        setTimeout(() => {
            EventBus.emit('data-loaded', data);
        }, 500); // Simulate async load
    }
}

export const dataLoader = new DataLoader();
