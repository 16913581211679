import React, { useRef, useState, useEffect } from 'react'
import {useTranslation} from 'react-i18next'
import Helper from '../../../services/Helper'
import {Api, Constants, Session} from 'scg.common-library'
import {useCurrentUser} from '../../../hooks/customHooks'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './avatar.css'

const AVATAR_WIDTH = (window.innerWidth*38) / 100

const AvatarItem = ({ current, selected, avatar, width=AVATAR_WIDTH, onSelected }) => {
    const {t} = useTranslation()
    const handleSelected = () => {
        if(typeof(onSelected) === "function"){
            onSelected(avatar)
        }
    }
    return (
        <div className={`avatar-item ${current ? 'active' : 'not-active'} ${!avatar.unlocked  ? 'locked' : 'unlocked'}`} style={{ width: width }}>
            <img src={avatar.link} alt={avatar.name} />
            {avatar.unlocked && selected?.id !== avatar.id && <span
                className='avatar-choose'
                title={t('profile.avatar.choose')}
                aria-label={t('profile.avatar.choose')}
                onClick={handleSelected}
            />}
        </div>
    )
}

const AvatarLoading = () => {
    const {t} = useTranslation()
    return (
        <div className='wrap-avatar-loading'>
            <FontAwesomeIcon icon="fas fa-gear loading-icon" spin/>
            <div>{t('profile.avatar.loading')}</div>
        </div>
    )
}

const Avatars = ({slideToShow = 1, current, avatars, onChange}) => {

    let i = -1;
    let avatarsRef = useRef(null)
    const AVATAR_ITEM_WIDTH = AVATAR_WIDTH/(slideToShow/2).toFixed((1));
    const [avatarSelected, setAvatarSelected] = useState(null)
    const [position, setPosition] = useState(2)
    const [avatarIndex, setAvatarIndex] = useState(0)

    useEffect( () => {

        if(avatars && current){
            let index = 0;
            avatars.forEach( (avatar) => {
                if( avatar.id === current ){
                    setAvatarIndex(index)
                    setAvatarSelected(avatar)
                    setPosition(index)
                    if(avatarsRef){
                        avatarsRef.slickGoTo(index)
                    }
                }
                index++
            } )
        }

    }, [avatars, current] )

    const handleChange = (avatar) => {
        if(typeof(onChange) === 'function'){
            onChange(avatar)
            setAvatarSelected(avatar)
        }
    }

    return (
        <div className='avatars-block'>
            <div className='avatar-inner'>
                <span className="avatar-name">{avatars[position]?.name}</span>
                <Slider
                    speed={500}
                    ref={slider => avatarsRef = slider}
                    centerMode={true}
                    slidesToScroll={1}
                    accessibility={true}
                    initialSlide={position}
                    slidesToShow={slideToShow}
                    afterChange = { (index) => setPosition(index) }
                >
                    {
                        avatars && avatars.map(avatar => {
                            i++
                            return (
                                <AvatarItem
                                    key={avatar.id}
                                    width={AVATAR_ITEM_WIDTH}
                                    avatar={avatar}
                                    selected={avatarSelected}
                                    current={avatarIndex === i}
                                    onSelected={(avatar) => handleChange(avatar)}
                                />
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    )

}

const ProfileAvatar = () => {

    // states
    const [avatars, setAvatars] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [currentAvatar, setCurrentAvatar] = useState(null)

    // hooks
    const {t} = useTranslation()
    const [loading, user] = useCurrentUser()

    useEffect( () => {
        Api.avatar.getAvatarUser()
            .then( response => {
                const images = Helper.isValidResponse(response)
                if( images.length ){
                    setAvatars(images)
                }
            } )
            .finally( () => setIsLoading(false) )
    }, [] )

    useEffect( () => {

        if( user ){
            setCurrentAvatar(user?.avatar?.id)
        }

    }, [user] )

    const handleAvatarChange = (avatar) => {
        if( avatar.unlocked ){
            Api.user.updateUser(user.id, {avatarId : avatar.id }, Session.getJwtAndRefreshToken())
                .then( response  => {
                    if( Constants.HTTP_OK === response.status ){
                        Helper.displayMessage(t("profile.avatar.success"), 'success');
                    }
                })
                .catch( () => Helper.displayMessage(t("error.generic"), 'error') )
        }
    }

    return (
        <div className="avatar-profile-container">
            {
                isLoading || loading ?
                    <AvatarLoading/> :
                    <Avatars
                        slideToShow={5}
                        avatars={avatars}
                        current={currentAvatar}
                        onChange={(avatar) => handleAvatarChange(avatar)}
                    />
            }
        </div>
    )
}

export default ProfileAvatar