import { Scene } from 'phaser';
import { showDialogue, interactivePolygons } from '../services/sceneUtils';
import { dataLoader } from '../services/dataloader';
import { EventBus } from '../services/EventBus';


export class NavigationChallenge extends Scene {
    constructor() {
        super('NavigationChallenge');
        this.story = {};
        this.props = {
            storyStep: 0,
            handleClick : () => {}
        };
        this.sceneLaunched = false;
    } 

    init(data) {
        this.props = data;
    }  

    preload() {
        this.story = dataLoader.getStory(this.props.storyStep);
    }

    create() {
        this.setupScene()
    }

    setupScene() {
        //Setup story
        if (!this.story) {
            console.error('Step data not found!');
            return;
        } 
        EventBus.emit('setStory', this.props.storyStep);
        showDialogue(this.story.description);
        
        // Setup image background
        const imageName = this.story.image.replace(".png", "")
        const sprite = this.add.sprite(0, 0, imageName).setOrigin(0, 0);
        sprite.displayWidth = this.sys.canvas.width;
        sprite.displayHeight = this.sys.canvas.height;

        // Polygones cliquables 
        const shapes = this.story.clickZones ? this.story.clickZones : [];
        if (shapes.length > 0 && Array.isArray(shapes)) interactivePolygons(this, sprite, shapes);

        EventBus.emit('current-scene-ready', this);
    }

    handleClick(action) {
        if (this.sceneLaunched) return; // Prevent multiple scenes from being launched

        if (this.props.handleClick) {
            this.props.handleClick(this, this.props.storyStep, action);
        }
    }

    
}
