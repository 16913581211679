import React from "react";

class TimerClock extends React.Component {
    static defaultProps = {
        width: 200,
        height: 200,
        lineWidth: 5,
        timeElapsedHandler: () => {}
    };

    constructor(props) {
        super(props);
        this.state = {
            timeLeft: props.timeLeft,
            coords: this.initCoords(props.timeLeft),
            currentStep: 1,
            width: props.width,
            height: props.height,
            lineWidth: props.lineWidth,
            center: [props.width / 2, props.height / 2],
            radius: Math.min(props.width, props.height) / 2 - props.lineWidth,
            imageLoaded: false,
            needleImageLoaded: false,
            svgImageLoaded: false 
        };

        this.image = new Image();
        this.image.src = '/img/quizz/HUD timer.svg'; 
        this.image.onload = () => {
            this.setState({ imageLoaded: true });
        };

        this.needleImage = new Image();
        this.needleImage.src = '/img/quizz/needle.svg';
        this.needleImage.onload = () => {
            this.setState({ needleImageLoaded: true });
        };

        this.svgImage = new Image();
        this.svgImage.src = '/img/quizz/needle.svg'; 
        this.svgImage.onload = () => {
            this.setState({ svgImageLoaded: true });
        };

        this.countDown = this.countDown.bind(this);
    }

    componentDidMount() {
        const timeLeft = this.state.timeLeft * 1000;
        if (this.state.imageLoaded && this.state.needleImageLoaded && this.state.svgImageLoaded) {
            this.draw(this.getCanvasContext());
        }
        this.intervalID = setInterval(() => this.tick(this.getCanvasContext()), 100);
        this.intervalID2 = setInterval(this.countDown, 1000);
        this.timeOutID = setTimeout(() => {
            clearInterval(this.intervalID);
            clearInterval(this.intervalID2);
            this.props.timeElapsedHandler();
        }, timeLeft);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
        clearInterval(this.intervalID2);
        clearTimeout(this.timeOutID);
    }

    initCoords(timeLeft) {
        const steps = timeLeft * 10;
        const coords = Array(steps);

        for (let i = 1; i <= steps; i++) {
            const angle = (Math.PI / 2) - (i * (2 * Math.PI / steps));
            const cos = Math.cos(angle);
            const sin = Math.sin(angle);
            coords[i] = [cos, sin];
        }

        return coords;
    }

    countDown() {
        this.setState({
            timeLeft: this.state.timeLeft - 1
        });
    }

    getCanvasContext() {
        const canvas = document.querySelector("#timerClock");
        return canvas.getContext("2d");
    }

    draw(ctx) {
        // Effacer le canevas
        ctx.clearRect(0, 0, this.state.width, this.state.height);

        // Dessiner l'image en tant que cercle extérieur si elle est chargée
        if (this.state.imageLoaded) {
            ctx.drawImage(
                this.image,
                this.state.center[0] - this.state.radius,
                this.state.center[1] - this.state.radius,
                this.state.radius * 2,
                this.state.radius * 2
            );
        }

        // Dessiner le remplissage basé sur le temps écoulé
        this.drawFill(ctx);
        //Dessin centre
        const centerRadius = this.state.radius / 10;
        const centerX = this.state.center[0] / 1.8;
        const centerY = this.state.center[1] / 0.85;
        ctx.beginPath();
        ctx.arc(centerX, centerY, centerRadius, 0, Math.PI * 2, true);
        // ctx.fill();
    }

    drawFill(ctx) {
        const progress = this.state.currentStep / (this.props.timeLeft * 10);
        const endAngle = (2 * Math.PI * progress) - Math.PI / 2;
        //centre
        const smallerRadius = this.state.radius * 0.45; 
        const centerX = this.state.center[0] / 1.8; 
        const centerY = this.state.center[1] / 0.85;

        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(
            centerX,
            centerY,
            smallerRadius,
            -Math.PI / 2,
            endAngle,
            false
        );
        ctx.closePath();
        //remplissage timer
        ctx.fillStyle = 'rgba(98, 152, 218, 0.3)';
        ctx.fill();
        if (this.state.svgImageLoaded) {
            //bordure
            const borderEndX = centerX + Math.cos(endAngle) * smallerRadius;
            const borderEndY = centerY + Math.sin(endAngle) * smallerRadius;
            const svgWidth = 30; 
            const svgHeight = 50; 

            ctx.save();
            ctx.translate(borderEndX, borderEndY);
            ctx.rotate(endAngle + Math.PI * 2.16); // Ajustez l'angle pour que l'image soit correctement alignée
            ctx.drawImage(this.svgImage, -svgWidth*1.4, -svgHeight/4.4, svgWidth, svgHeight);
            ctx.restore();
        }
    }

    tick(ctx) {
        this.draw(ctx);

        this.setState({
            currentStep: this.state.currentStep + 1
        });
    }

    render() {
        return (
            <>
                <canvas
                    id="timerClock"
                    width={this.props.width}
                    height={this.props.height}>
                </canvas>
            </>
        );
    }
}

class TimerText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeLeft: props.timeLeft
        };
    }

    componentDidMount() {
        const timeLeft = this.state.timeLeft * 1000;
        this.intervalID = setInterval(() => this.countDown(), 1000);
        this.timeOutID = setTimeout(() => clearInterval(this.intervalID), timeLeft);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
        clearTimeout(this.timeOutID);
    }

    countDown() {
        this.setState({
            timeLeft: this.state.timeLeft - 1
        });
    }

    render() {
        return (
            <>
                {this.state.timeLeft}
            </>
        );
    }
}

export { TimerText, TimerClock };
