import React, { useState, useContext, useEffect } from "react"
import { createPortal } from "react-dom"
import { NavLink } from "react-router-dom"
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import {Api, Session} from "scg.common-library"
import {routes as Routing} from "../../services/RoutesHelper"
import Helper from "../../services/Helper"
import VolumeContext from "../../context/VolumeContext";
import SoundService from "../../services/SoundService"
import i18next from 'i18next'
import './parameterModal.css'

// VolumeSlider use for display slider inside volume
const VolumeSlider = styled(Slider)(({ theme }) => ({
    color:'#56FEFF',
    height: 14,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
      height: 18,
      width: 18,
      border: '2px solid #56FEFF',
      backgroundColor: '#fff',
      boxShadow: '-2px 0px 2px #00000029',
      '&:before': {
        boxShadow:
          '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
      },
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 'normal',
      top: -6,
      backgroundColor: 'unset',
      color: theme.palette.text.primary,
      '&::before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      },
    },
    '& .MuiSlider-track': {
      border: 'none',
      height: 14,
    },
    '& .MuiSlider-rail': {
      opacity: 0.47,
      backgroundColor: '#EFF1F8',
    },
}));

// Display volume item
const VolumeItem = ({ name, defaultValue, onChange }) => {
    
    const [volume, setVolume] = useState(defaultValue)

    const handleChange = (event) => {
        const value = event.target.value;
        setVolume(value)
        if( typeof(onChange) === 'function' ){
            onChange( parseFloat(value) )
        }
    }

    return  <div className="volumeItem">
                <div className="volume-labels">
                    <span className="volume-name">{name}</span>
                    <span className="volume-value">{`${( volume*100 ).toFixed(0)}%`}</span>
                </div>
                <VolumeSlider 
                    min={0}
                    max={1}
                    step={0.01}
                    value={volume} 
                    onChange={handleChange} 
                    aria-label={`Volume ${name}`} 
                />
            </div>
}

const LanguageBloc = ({ current, languages, onChange }) => {

    let index = -1;
    const LANG_WIDTH = (window.innerWidth*11.4) / 100
    
    // state
    const [position, setPosition] = useState(0)
    const [langIndex, setLangIndex] = useState(0)

    useEffect( () => {

        if( current && languages ){
            let i = 0
            languages.forEach( lang => {
                if( lang.code === current.code ){
                    setLangIndex(i)
                    setPosition((-1*LANG_WIDTH)*i)
                }
                i++;
            } )
        }

    }, [current, languages, LANG_WIDTH] )

    const handleNavigation = (direction) => {
        if( direction === 'left' ){
            setLangIndex( index =>  {
                const languageIndex = index-1
                if( typeof(onChange) === 'function' ){
                    onChange(languages[languageIndex])
                }
                return languageIndex
            } )
            setPosition( pos => pos+LANG_WIDTH )
        }
        if( direction === 'right' ){
            setLangIndex( index =>  {
                const languageIndex = index+1
                if( typeof(onChange) === 'function' ){
                    onChange(languages[languageIndex])
                }
                return languageIndex
            } )
            setPosition( pos => pos-LANG_WIDTH )
        }
    }

    return (
            <div className="langue-block">
                <span className="title-block">{i18next.t('modalParameter.title')}</span>
                <div className="langage-wrapper">
                    <div className="langage-carrousel">
                        <span className={`crt-carousel prev ${ langIndex <= 0 ? 'disabled' : 'active' }`} onClick={() => handleNavigation('left')} />
                        <div className="carousels">
                            <div className="wrap-carousel" style={{ width: LANG_WIDTH * languages?.length, translate: position }}>
                                {
                                    languages.map( language => {
                                        index++
                                        return <span key={language.id} className={`carousel-item ${index  === langIndex ? 'active' : 'not-active'}`}>{`${language.name} : ${language.code}`}</span>
                                    } )
                                }
                            </div>
                            
                        </div>
                        <span className={`crt-carousel next ${ langIndex >= languages?.length-1 ? 'disabled' : 'active' }`} onClick={() => handleNavigation('right')} />
                    </div>
                    <div className="line-sep"></div>
                </div>
            </div>
    )
}

const SonsBloc = () => {

    const { volumes, setVolumes } = useContext( VolumeContext );

    const handleVolumeChange = (volume, value) => {
        SoundService.setDefaultVolumes({ [volume] : value  })
        if( volume === 'music' ){
            SoundService.updateMusicVolume(value)
        }
        if( volume === 'general' ){
            SoundService.setGlobalVolume(value)
        }
        setVolumes({...volumes, ...{ [volume] : value  }})
    }

    return  <div className="sons-block">
                <span className="title-block">{i18next.t('modalParameter.sonsTitle')}</span>
                <div className="volume-wrapper">
                    <VolumeItem name={i18next.t('modalParameter.general')} defaultValue={volumes?.general} onChange={ (value) => handleVolumeChange('general', value) } />
                    <VolumeItem name={i18next.t('modalParameter.vfx')} defaultValue={volumes?.sounds} onChange={ (value) => handleVolumeChange('sounds', value) } />
                    <VolumeItem name={i18next.t('modalParameter.music')} defaultValue={volumes?.music} onChange={ (value) => handleVolumeChange('music', value) } />
                    <VolumeItem name={i18next.t('modalParameter.dialogs')} defaultValue={volumes?.dialog} onChange={ (value) => handleVolumeChange('dialog', value) } />
                </div>
            </div>
}

const LinksBloc = () => {
    return  <div className="link-block">
                <ul className="links-wrapper">
                    <li><NavLink to={Routing.mentions.path} className='link-item' title={i18next.t('modalParameter.mentions')}>{i18next.t('modalParameter.mentions')}</NavLink></li>
                    <li><NavLink to={Routing.credits.path} className='link-item' title={i18next.t('modalParameter.credits')}>{i18next.t('modalParameter.credits')}</NavLink></li>
                    <li><NavLink to={Routing.politique.path} className='link-item' title={i18next.t('modalParameter.politique')}>{i18next.t('modalParameter.politique')}</NavLink></li>
                    <li><NavLink to={Routing.changelog.path} className='link-item' title={i18next.t('modalParameter.changelog')}>{i18next.t('modalParameter.changelog')}</NavLink></li>
                </ul>
            </div>
}

const ParameterModal = ({ user, show, onClose}) => {
    
    const [currentLanguage, setCurrentLanguage] = useState(null)
    const [languages, setLanguages] = useState(null)

    // onMount
    useEffect( () => {
        Api.language.getLanguages()
            .then( (langsResponse) => {
                const langs = Helper.isValidResponse(langsResponse)
                if(langs) {
                    setLanguages(langs)
                }
            } ) 
    }, [] )

    // onDidUpdate
    useEffect( () => {
        if( user ){
            setCurrentLanguage(user.language)
        }
    }, [user] )

    const handleLanguageChange = (language) => {
        i18next.changeLanguage(language.code).then(() => {
            setCurrentLanguage(language)
            user.currentLanguage = language
            user.language = language.id
            Api.user.updateUser(user.id, user, Session.getJwtAndRefreshToken())
                .catch( (e) => console.error(e) )
        })
    }

    return show ?
        createPortal(<>
                <section id="modalParameter" className="modal-overlay">
                    <div className="modal-wrapper">
                        <div className="modal">
                            <div className="modal-header">
                                <h4 className="modal-title">{i18next.t('modalParameter.modalTitle')}</h4>
                                <button type="button" className="modal-close-button" title={i18next.t('modalParameter.modalClose')} onClick={onClose}>
                                    <img src='/img/parametres/buttonClose.svg' alt={i18next.t('modalParameter.modalClose')} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="parameters-wrapper">
                                    <LanguageBloc 
                                        languages={languages} 
                                        current = {currentLanguage}
                                        onChange={ (language) => handleLanguageChange(language) }
                                    />
                                    <SonsBloc />
                                    <LinksBloc />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>,
            document.body
        ) : null
}

export default ParameterModal