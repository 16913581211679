import React from "react"
import {NavLink, useLocation} from "react-router-dom"
import {routes as Routing} from "../../../services/RoutesHelper"
import {useTranslation} from "react-i18next"
import "./profileGeneralSidebar.css"


const ProfileGeneralSidebar = () => {
    const {t} = useTranslation()

    const location = useLocation()

    const navLinkClassName = ({isPending, pathname}) => {
        return isPending || pathname !== Routing.profile_general.path ? "" : "active"
    }

    return (
        <div className="profile-generalSidebar-container">
            <div className="profile-generalSidebar-links">
                <NavLink to={Routing.profile_general_infos.path}
                         className={`profile-link ${navLinkClassName({pathname: location.pathname})}`}>
                    <span>{t("profile.generalMenu.informations")}</span>
                </NavLink>
                <NavLink to={Routing.profile_general_icons.path}
                         className={`profile-link ${navLinkClassName({pathname: location.pathname})}`}>
                    <span>{t("profile.generalMenu.icons")}</span>
                </NavLink>
                <NavLink to={Routing.profile_general_frames.path}
                         className={`profile-link ${navLinkClassName({pathname: location.pathname})}`}>
                    <span>{t("profile.generalMenu.frames")}</span>
                </NavLink>
                <NavLink to={Routing.profile_general_titles.path}
                         className={`profile-link ${navLinkClassName({pathname: location.pathname})}`}>
                    <span>{t("profile.generalMenu.titles")}</span>
                </NavLink>
            </div>
        </div>
    )

}

export default ProfileGeneralSidebar