import React from "react"
import i18n from "i18next"
import {NavLink} from "react-router-dom"
import {routes as Routing} from "../../services/RoutesHelper"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ProfileIcon from "../profile/general/ProfileIcon"
import ProfileTitle from "../profile/general/ProfileTitle"

export class UserLevel extends React.Component {
    static defaultProps = {
        user: null
    }

    constructor(props) {
        super(props)

        this.state = {
            currentLevel: "loading...",
            currentXpPercent: 0,
            currentXpFlat: 0,
            minExperience: 0,
            maxExperience: 1,
            menuOpen: false,
            showMessage: false
        }
        this.toggleMenu = this.toggleMenu.bind(this)
        this.closeMenu = this.closeMenu.bind(this)
    }

    componentDidMount() {
        if (this.props.user) {
            const userLvl = this.props.user.level
            const currentXpFlat = this.props.user.currentExperience - userLvl.minExperience
            const maxXp = userLvl.maxExperience - userLvl.minExperience
            this.setState({
                currentXpPercent: ((currentXpFlat / maxXp) * 100).toFixed(2),
                currentXpFlat: currentXpFlat,
                currentLevel: userLvl.name.toUpperCase(),
                maxExperience: userLvl.maxExperience
            })
        }
    }

    toggleMenu() {
        this.setState((prevState) => {
            return {menuOpen: !prevState.menuOpen}
        })
    }

    closeMenu() {
        this.setState({menuOpen: false})
    }

    actionShowMessage = (show = true) => {
        this.setState({showMessage: show})
    }

    render() {
        return <div className="user-level">
            <div className="user-avatar-small">
                <div className="logo-img" onClick={this.toggleMenu} onBlur={this.closeMenu}>
                    <ProfileIcon iconObj={this.props.user.icon} width={100} height={105} active/>
                </div>
                {this.state.menuOpen && <UserMenu isActiveLicence={this.props.isActiveLicence}/>}
                {this.props.user.title &&
                    <ProfileTitle titleObj={this.props.user.title}/>
                }
            </div>
            <div className="user-xp-bar">
                <div className="user-name">
                    <span>{this.props.user.pseudo} #{this.props.user.tag}</span>
                </div>
                <div className="userxp-wrapper" title={`${this.state.currentXpFlat} / ${this.state.maxExperience}`}>
                    <div className={`userxp-progress ${this.state.currentXpPercent <= 0 ? "userxp-hidden" : ""}`}
                         style={{width: `${this.state.currentXpPercent}%`}}>
                        <div className="progress-bar">
                            <div className="sparkle"></div>
                            <div className="sparkle"></div>
                            <div className="sparkle"></div>
                            <div className="sparkle"></div>
                            <div className="sparkle"></div>
                            <div className="sparkle"></div>
                        </div>
                    </div>
                </div>
                <div className="actual-level">
                    <div><span className="label">{i18n.t("game.level")} </span> : {this.state.currentLevel} </div>
                    <UserMinLevelNegativePoints user={this.props.user} action={this.actionShowMessage}/>
                </div>
                <div
                    className={`hover-div ${this.state.showMessage ? "" : "hover-div-hidden"}`}
                    onMouseEnter={this.actionShowMessage}
                    onMouseLeave={() => this.actionShowMessage(false)}
                >
                    <div className="content">
                        <div className="label">
                            {i18n.t("game.advice")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

function UserMinLevelNegativePoints({user, action}) {
    if (user.currentExperience > user.society.parameter.minLevelNegativePoints) {
        return <div className="img-icone"><img src="/img/icon/icon-plusminus.svg" alt="minplus"
                                               onMouseEnter={() => action(true)} onMouseLeave={() => action(false)}/>
        </div>
    }
    return null
}

function UserMenu({...props}) {
    return <div className="user-menu" role="menu">
        <div className="user-menu-item" role="menuitem">
            <NavLink to={Routing.profile_general_infos.path} title={i18n.t("game.profile")}>
                <FontAwesomeIcon icon="fa-solid fa-circle-user"/> {i18n.t("game.profile")}
            </NavLink>
        </div>
        <div className="user-menu-item" role="menuitem">
            <NavLink to={(props.isActiveLicence) ? Routing.information_center.path : "#"}
                     title={i18n.t("game.information_center")}
                     style={(props.isActiveLicence) ? {} : {cursor: "not-allowed"}}
            >
                <FontAwesomeIcon icon="fa-solid fa-circle-info"/> {i18n.t("game.information_center")}
            </NavLink>
        </div>
        <div className="user-menu-item" role="menuitem">
            <NavLink to={Routing.parameters.path} title={i18n.t("game.parameters")}>
                <FontAwesomeIcon icon="fas fa-gears"/> {i18n.t("route.parameters")}
            </NavLink>
        </div>
        <div className="user-menu-item" role="menuitem">
            <NavLink reloadDocument to={Routing.app_logout.path} title={i18n.t("session.logout")}>
                <FontAwesomeIcon icon="fas fa-power-off"/> {i18n.t("route.logout")}
            </NavLink>
        </div>
    </div>

}