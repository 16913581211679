import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import InputPasswordMui from '../../../general/InputPasswordMui';
import InputTextMui from '../../../general/InputTextMui';
import { Api, Session } from 'scg.common-library';
import Helper from '../../../../services/Helper';
import i18n from 'i18next';
import ButtonMui from '../../../general/ButtonMui';
import IconCheckboxMui from '../../../general/IconCheckboxMui';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Loading } from '../../../general/Loading';
import { useContext } from 'react';
import { UserProfileContext } from '../../../../context/UserProfileContext';
import './profileGInfos.css'
import '../../../general/tooltipMui.css'


const ProfileGInfos = () => {
	const { t } = useTranslation();
    const { user, setUser, loading } = useContext(UserProfileContext)

    // TODO: abdoul - ajouter gestion de pseudo identique et en helperText l'erreur 419 pseudo identique
	// eslint-disable-next-line no-unused-vars
	const [infoInput, setInfoInput] = useState({
		name: { value: "", error: false, helperText: "" },
		surname: { value: "", error: false, helperText: "" },
		pseudo: { value: "", error: false, helperText: "" }
    })
    
	const [password, setPassword] = useState({
		currentPassword: { value: "", error: false, helperText: "" },
		newPassword: { value: "", error: false, helperText: "" },
		passwordConfirm: { value: "", error: false, helperText: "" }
	})
    const [disablePasswordSubmit, setDisablePasswordSubmit] = useState(true)
	
	// Handling form left --------------------------------------------------------------------------
	useEffect(() => {
		setInfoInput({
			name: { value: user?.name, error: false, helperText: "" },
			surname: { value: user?.surname, error: false, helperText: "" },
			pseudo: { value: user?.pseudo, error: false, helperText: "" }
		})

	}, [user])
	
	/**
	 * @description: Sauvegarde des valeurs input text
	 */
	const handleSaveInput = async (info, name) => {
		const userInfo = { ...info, roles: user?.roles };

		// Validation des inputs
		if (haveInputError(info, name)) return
		
		// //TODO: Mettre à jour le cookie de session avec le nouveau nom de l'utilisateur
		const response = await Api.user.updateUser(user?.id, userInfo, Session.getJwtAndRefreshToken())
		const result = Helper.isValidResponse(response)

		if (result) {
			setUser({ ...user, ...info })
			Helper.displayMessage(t("parameter.form.updated_success"))
		} else {
			//TODO: afficher un message d'erreur à l'utilisateur avec la raison précise de l'echec
			Helper.displayMessage(i18n.t("error.generic"), 'error')
		}
	}

	/**
	 * @description: Validation des inputs text
	 * pseudo: 4 à 25 caractères
	 * champ vide
	 */
	const haveInputError = (info, name) => {
		// Si le pseudo est inférieur à 4 ou supérieur à 25 caractères
		if (info.pseudo && (info.pseudo.length < 4 || info.pseudo.length > 25)) {
			setInfoInput({ ...infoInput, "pseudo": { value: info.pseudo, error: true, helperText: t("parameter.formValidation.username_length") } });
			return true
		}
		// Si vide
		if (info[name] === "" || info[name] === undefined) {
			console.log("zzzzzzzzzz");
            setInfoInput({ ...infoInput, [name]: { value: info[name], error: true, helperText: t("parameter.formValidation.required") } });
            return true
		}
		
		return false
	}

	/**
	 * @description: Sauvegarde des valeurs des checkbox
	 */
	const handleCheckboxChange = async (info) => {
        const userInfo = { ...info, roles: user?.roles };
        
      	// //TODO: Mettre à jour le cookie de session avec le nouveau nom de l'utilisateur
		const response = await Api.user.updateUser(user?.id, userInfo, Session.getJwtAndRefreshToken())
		const result = Helper.isValidResponse(response)

		if (result) {
			setUser({ ...user, ...info })
			Helper.displayMessage(t("parameter.form.updated_success"))
		} else {
			//TODO: afficher un message d'erreur à l'utilisateur avec la raison précise de l'echec
			Helper.displayMessage(i18n.t("error.generic"), 'error')
		}
    }
	
	// End Handling form left --------------------------------------------------------------------------

	// Handling form right --------------------------------------------------------------------------
	
	/**
	 * @description: handleChangePassword with validations
	 * champ vide
	 * longueur >= 8
	 * au moins un chiffre
	 * au moins une minuscule
	 * au moins une majuscule
	 * au moins un caractère spécial
	 * les deux mots de passe ne correspondent pas
	 */
	const handleChangePassword = (e) => {
		const { name, value } = e.target;
		let error = false;
		let helperText = "";
		let disableSubmit = false;

		if (password.currentPassword.value === "" || password.newPassword.value === "" || password.passwordConfirm.value === "") {
			disableSubmit = true;
		}

		// Gestion des validations
		if (value === "") {
			error = true;
			disableSubmit = true;
			helperText = t("parameter.formValidation.required");
		} else if (!checkPasswordLength(value)) {
			error = true;
			disableSubmit = true;
			helperText = t("activate.password.security.label") + t("activate.password.security.length");
		} else if (!checkPasswordNumber(value)) {
			error = true;
			disableSubmit = true;
			helperText = t("activate.password.security.label") + t("activate.password.security.number");
		} else if (!checkPasswordLowerCase(value)) {
			error = true;
			disableSubmit = true;
			helperText = t("activate.password.security.label") + t("activate.password.security.lowercase");
		} else if (!checkPasswordUppercase(value)) {
			error = true;
			disableSubmit = true;
			helperText = t("activate.password.security.label") + t("activate.password.security.uppercase");
		} else if (!checkPasswordSpecialChar(value)) {
			error = true;
			disableSubmit = true;
			helperText = t("activate.password.security.label") + t("activate.password.security.specialchar");
		} else if (checkPasswordsMatchs(e.target)) {
			error = true;
			disableSubmit = true;
			helperText = t("activate.password.security.match");
		}

		setPassword({ ...password, [name]: { value: value, error: error, helperText: helperText } });
		setDisablePasswordSubmit(disableSubmit);
		
	};
	const checkPasswordLength = password => password.length >= 8
	const checkPasswordNumber = password => (new RegExp(/[1-9]/)).test(password)
	const checkPasswordLowerCase = password => (new RegExp(/[a-z]/)).test(password)
	const checkPasswordUppercase = password => (new RegExp(/[A-Z]/)).test(password)
	const checkPasswordSpecialChar = password => (new RegExp(/[-_#@$%*!?&]/)).test(password)
	const checkPasswordsMatchs = passwordConfirm => passwordConfirm.name === "passwordConfirm" && passwordConfirm.value !== password.newPassword.value
	
	const handleSubmitPassword = async () => {
		setDisablePasswordSubmit(true)

		const response = await Api.user.changeUserPassword(user?.id, password.currentPassword.value, password.newPassword.value, Session.getJwtAndRefreshToken())
		if (response.status === 200) {
			Helper.displayMessage(t("activate.password.updated_success"))

			setPassword({
				currentPassword: { value: "", error: false, helperText: "" },
				newPassword: { value: "", error: false, helperText: "" },
				passwordConfirm: { value: "", error: false, helperText: "" }
			})
		}
		else {
			if (response.status === 400) {
				setPassword({ ...password, "currentPassword": { value: password.currentPassword.value, error: true, helperText: t("activate.password.wrong_current") } });
			} else {
				Helper.displayMessage(t("error.generic"), 'error')
			}
		}
	}
	// End Handling form right --------------------------------------------------------------------------

	return (
		<>
			{
				loading ?
					<Loading /> :
					
          <div className='profileGInfo-container'>
            <div className='profileGInfo-forms'>
              <div className='profileGInfo-form-left'>
                <div className="profileGInfo-form-title">{t("profile.generalMenu.general")}</div>
                <span>
                  <div htmlFor="" className='profileGInfo-form-label'>{t("parameter.form.name")}</div>
                  <InputTextMui placeholder={t("parameter.form.name")} name="name" handleSave={handleSaveInput} value={user?.name} inputObj={infoInput} validationText={t("parameter.formValidation.required")} />
                </span>
                <div>
                  <div htmlFor="" className='profileGInfo-form-label'>{t("parameter.form.surname")}</div>
                  <InputTextMui placeholder={t("parameter.form.surname")} name="surname" handleSave={handleSaveInput} value={user?.surname} inputObj={infoInput} validationText={t("parameter.formValidation.surname")} />
                </div>
                <div>
                  <div className="flex-start">
                  <div htmlFor="" className='profileGInfo-form-label'>{t("parameter.form.pseudo")}</div>
                  <Tooltip title={<div style={{ fontSize: '0.8rem' }}>{t("parameter.form.tooltip_username_length")}</div>} placement='top' className='Mui-tooltip-futurist' arrow>
                    <InfoOutlinedIcon className='info-icon' />
                  </Tooltip>
                  </div>
                    <InputTextMui placeholder={t("parameter.form.pseudo")} name="pseudo" inputProps={{ minLength: 4, maxLength: 25 }} handleSave={handleSaveInput} value={user?.pseudo} inputObj={infoInput} validationText={t("parameter.formValidation.pseudo")} endAdornment={`#${user?.tag}`} />
                </div>
                
                <div>
                  <div className='profileGInfo-form-subtitle'>{t("parameter.form.society")}</div>
                  <div className='profileGInfo-form-info'>{user?.society?.name}</div>
                </div>

                <div>
                  <div className='profileGInfo-form-subtitle'>{t("parameter.form.mail")}</div>
                  <div className='profileGInfo-form-info'>{user?.email}</div>
                </div>

                <div className='flex-sb'>
                  <div className='profileGInfo-form-subtitle'>
                    {t("parameter.form.show_username_local")}
                    <Tooltip title={<div style={{ fontSize: '0.8rem' }}>{t("parameter.form.tooltip_league")}</div>} placement='top' className='Mui-tooltip-futurist' arrow>
                      <InfoOutlinedIcon className='info-icon' />
                    </Tooltip>
                  </div>
                  <IconCheckboxMui name="showPseudoLocal" onChange={handleCheckboxChange} checked={user?.showPseudoLocal} />
                </div>

                <div className='flex-sb'>
                  <div className='profileGInfo-form-subtitle'>
                    {t("parameter.form.show_username_global")}
                    <Tooltip title={<div style={{ fontSize: '0.8rem' }}>{t("parameter.form.tooltip_league")}</div>} placement='top' className='Mui-tooltip-futurist' arrow>
                      <InfoOutlinedIcon className='info-icon' />
                    </Tooltip>
                  </div>
                  <IconCheckboxMui name="showPseudoGlobal" onChange={handleCheckboxChange} checked={user?.showPseudoGlobal} />
                </div>
              </div>

              <div className="profileGInfo-form-right">
                <div className="profileGInfo-form-title">{t("profile.generalMenu.security")}</div>
                <div>
                  <div htmlFor="" className='profileGInfo-form-label'>{t("activate.password.current")}</div>
                  <InputPasswordMui placeholder={t("activate.password.current")} handleChangePassword={handleChangePassword} name="currentPassword" passwordObj={password} />
                </div>
                <div>
                  <div htmlFor="" className='profileGInfo-form-label'>{t("activate.password.create")}</div>
                  <InputPasswordMui placeholder={t("activate.password.create")} handleChangePassword={handleChangePassword} name="newPassword" passwordObj={password} />
                </div>
                <div>
                  <div htmlFor="" className='profileGInfo-form-label'>{t("activate.password.confirm")}</div>
                  <InputPasswordMui placeholder={t("activate.password.confirm")} handleChangePassword={handleChangePassword} name="passwordConfirm" passwordObj={password} />
                </div>
                <div>
                  <ButtonMui value={t("parameter.save")} disabled={disablePasswordSubmit} handleSubmit={handleSubmitPassword} icon={disablePasswordSubmit ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />} />
                </div>
        
                <div className='flex-sb'>
                  <span className='profileGInfo-form-subtitle'>{t("parameter.form.acceptNotification")}</span>
                  <IconCheckboxMui name="acceptNotification" onChange={handleCheckboxChange} checked={user?.acceptNotification} />
                </div>
              </div>
            </div>
            <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" className="profileGInfo-cookielink" rel="noreferrer">{t("profile.cookieLink")}</a>
          </div>
			}
		</>
	)
}

export default ProfileGInfos