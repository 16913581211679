import React from 'react'
import ProfileGeneralSidebar from './ProfileGeneralSidebar'
import "./profileGeneral.css"
import { Outlet } from 'react-router-dom'

const ProfileGeneral = () => {
	return (
		<div className='profile-general-container'>
		    <ProfileGeneralSidebar />
			<Outlet/>
		</div>
	)
}

export default ProfileGeneral