import React from "react"
import SoundService from "../../services/SoundService"
import Helper from "../../services/Helper"
import moment from "moment"
import LockIcon from '@mui/icons-material/Lock'
import i18n from "i18next"
import {NavLink} from "react-router-dom"

import "./button.css"

class SimpleButton extends React.Component {

    render() {
        let {type, className, name, title, onClick, onMouseEnter, children, disabled} = this.props

        type = type ?? "button"
        className = className ?? ""

        return <button type={type} className={`simple-button ${className}`}
                       name={name} title={title} onClick={onClick} onMouseEnter={onMouseEnter} disabled={disabled}>
            {children}
        </button>
    }
}

class ButtonPrimary extends React.Component {

    handleClick() {
        // console.log("button clicked")
        SoundService.playSound("tone.wav", "buttonClicked", "sounds")
    }

    handleHover() {
        // console.log("button clicked")
        SoundService.playSound("play.wav", "buttonHovered", "sounds")
    }

    getUniqueId() {
        return Helper.generateUUID(5)
    }

    render() {
        let {type, className, name, title, context, onClick, onMouseEnter, children, disabled, nosound} = this.props

        type = type ?? "button"
        className = className ?? ""
        if (!nosound) {
            onClick = onClick ?? this.handleClick.bind(context)
            onMouseEnter = onMouseEnter ?? this.handleHover.bind(context)
        }

        //id={`button-${this.getUniqueId()}`}
        return <button type={type} className={`neoButton-primary ${className}`}
                       name={name} title={title} onClick={onClick} onMouseEnter={onMouseEnter} disabled={disabled}>
            {children}
        </button>
    }
}

class TimingButton extends React.Component {
    static defaultProps = {
        step: 1,
        delay: 60000,
        eventType: "évènement",
        classPrefix: "event",
        iconClassName: "fa-clipboard-question",
        buttonName: "button",
        nextEventTime: 0
    }

    constructor(props) {
        super(props)
        this.state = {
            nextEventTime: 0,
            eventStatus: false
        }
        this.decrement = this.decrement.bind(this)
    }

    componentDidMount() {
        this.setState((prevState, props) => ({
            nextEventTime: props.nextEventTime,
            eventStatus: props.nextEventTime === 0
        }))
        this.play()
    }

    decrement() {
        this.setState((prevState, props) => {
            let nextEventTime = prevState.nextEventTime - props.step
            nextEventTime = (nextEventTime < 0) ? 0 : nextEventTime
            return {
                nextEventTime: nextEventTime,
                eventStatus: nextEventTime === 0
            }
        })
    }

    play() {
        setInterval(this.decrement, this.props.delay)
    }

    render() {
        let timeLeft = moment.utc(moment.duration(this.state.nextEventTime, "minutes").asMilliseconds()).format("HH:mm")
        let indicationTime = i18n.t("game.event.hours")
        if (this.state.nextEventTime < 60) {
            indicationTime = i18n.t("game.event.minutes")
            timeLeft = moment.utc(moment.duration(this.state.nextEventTime, "minutes").asMilliseconds()).format("mm")
        }
        const locked = ((!this.state.eventStatus && this.state.nextEventTime !== 0) || this.props.forceBlockButton)
        const eventType = this.props.eventType.charAt(0).toUpperCase() + this.props.eventType.slice(1)
        let timeText = i18n.t("game.event.eventTime", {event: eventType, time: timeLeft})
        if (this.state.nextEventTime < 1) {
            indicationTime = ""
            timeText = i18n.t("game.event.lessOneMinutes", {event: eventType})
        }
        if (this.props.forceBlockButton) {
            indicationTime = ""
            timeText = i18n.t("game.event.eventBlocked", {event: eventType})
        }
        return <>
            <div className="container">
                <div className={`hover-div ${locked ? "unavailable" : ""}`}>
                    <div className="hover-div">
                        {i18n.t("game.event.eventOfTheDay", {event: eventType})}
                    </div>
                </div>
            </div>
            <span className={`${this.props.classPrefix}-timer-message ${locked ? "" : "available"}`}>
                {locked
                    ? <>
                        <div className="tag information">
                            <LockIcon sx={{ fontSize: 14 }}/>
                            <span> {timeText + indicationTime}</span>
                        </div>
                    </>
                    : <>
                        <div className="tag">{i18n.t("game.event.eventAvailable", {event: eventType})}</div>
                    </>
                }
            </span>
        </>
    }
}

const BackButton = (props) => {
    const {to = "#", text = i18n.t("component.button.back"), title = i18n.t("component.button.back")} = props
    return <NavLink to={to} style={{textDecoration: "none", paddingTop: 20}} title={title} {...props}>
        <div className="btn-back">
            <div className="btn-text">{text}</div>
            <div className="ligne-top"></div>
        </div>
    </NavLink>
}

const NextButton = (props) => {
    const {to = "#", text = i18n.t("component.button.next"), title = i18n.t("component.button.next")} = props
    return <NavLink to={to} style={{textDecoration: "none", paddingTop: 20}} title={title} {...props}>
        <div className="btn-next">
            <div className="btn-text">{text}</div>
            <div className="ligne-top"></div>
        </div>
    </NavLink>
}

export {
    SimpleButton, ButtonPrimary, TimingButton, BackButton, NextButton
}