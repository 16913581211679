import React from "react"
import { Tooltip } from "@mui/material"
import i18n from "i18next"
import "./userTitle.css"

const UserTitle = ({titleObj = {"name": ""}, active = false, handleChange, fontSize = 16, style}) => {
    return (
        <Tooltip
            title={titleObj?.visible ?
                <div style={{ fontSize: '0.82rem' }}>{titleObj.description}</div> :
                <div style={{ fontSize: '0.82rem' }}>{i18n.t("profile.generalMenu.titleLocked_tooltip")}</div>}
            placement='top'
        >
            <div
                className={`user-title ${active && "active"} ${(!titleObj?.unlocked || !titleObj?.visible) && "cursor-default"}`}
                style={{fontSize: fontSize, padding: style && style.padding ? style.padding : "3px 1.2rem", ...style}}
                onClick={() => {(titleObj?.unlocked && titleObj?.visible) && handleChange(titleObj, active)}}
            >
                
                {(!titleObj?.unlocked || !titleObj?.visible) && <div className='user-title-lockedbg'></div>} 
                {titleObj?.name}
            </div>
        </Tooltip>
    )
}

export default UserTitle
