import React from 'react';
import Button from '@mui/material/Button';
import './buttonMui.css';

const ButtonMui = ({ variant = "outlined", value, theme = "futurist", disabled = false, icon, handleSubmit }) => {
    return (
        <Button
            className={`MuiButton-${theme}`}
            variant={variant}
            startIcon={icon && icon}
            disabled={disabled}
            onClick={!disabled ? ((e) => handleSubmit()) : ((e) => { })}
        >
           <span className='ligne-top'></span>
           <span> {value} </span>
           <span className='ligne-bottom'></span>
        </Button>
    );
};

export default ButtonMui;
