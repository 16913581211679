import React, {useState, useEffect} from "react"
import {useTranslation} from "react-i18next"
import {BackButton} from "../general/Button"
import {Loading} from "../general/Loading"
import {routes as Routing} from "../../services/RoutesHelper"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./changelog.css"
import "./markdown.css"

export default function ChangelogPage() {
    const {t} = useTranslation()
    const [markdown, setMarkdown] = useState("")
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/CHANGELOG.md`)
            .then(response => response.text())
            .then(data => setMarkdown(data))
            .finally(() => setIsLoading(false))
    }, [])

    return <>
        {isLoading
            ? <Loading/>
            : <>
                <BackButton to={Routing.game.path} title={t("game.changelog.back")} className="changelog-backButton"/>
                <div className="changelog-content">
                    <h1>{t("game.changelog.title")}</h1>
                    <div className="wrap-markdown">
                        <div className="markdown-body">
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
                        </div>
                    </div>
                </div>
            </>
        }
    </>
}