
import React, { useState, useEffect } from 'react';
import './QuizzGap.css';

function QuizzGaps(props) {
  let { initialItems, text, updateAnswerData, isResponseSelected, isDraggable } = props;
  const [items, setItems] = useState(initialItems);
  const [droppedItems, setDroppedItems] = useState(Array(initialItems.length).fill(null));
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);
  const [filledGaps, setFilledGaps] = useState(
    droppedItems.filter(item => item !== null).length
  );  

  const [droppedIntoIndex, setDroppedIntoIndex] = useState(null);
  const [intoEmpty, setIntoEmpty] = useState(true);
  useEffect(() => {
    updateAnswerData(droppedItems, filledGaps > 0);
  }, [droppedItems, updateAnswerData,filledGaps]);

  const handleDragStart = (e, item) => {
    if (isDraggable) {
      e.preventDefault();
      return;
    }
    e.dataTransfer.setData('text/plain', JSON.stringify(item));
  };

  const isLineNull = (tableau) => {
    let numberNull = 0;
    for (let i = 0; i < tableau.length; i++) {
      if (tableau[i] !== null) {
        numberNull++;
      }
    }
    return numberNull;
  }

  useEffect(() => {
    const numberSelected = isLineNull(droppedItems)
    const ifResponseSelected = (numberSelected > 0) ? true : false;
    if (filledGaps === 0) {
      setIntoEmpty(true); 
    } else {
      setIntoEmpty(false);
    }
    updateAnswerData(droppedItems, ifResponseSelected);
  }, [droppedItems, isResponseSelected, updateAnswerData,filledGaps]);

  const handleDrop = (e, index) => {
    if (isDraggable) {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    const droppedItem = JSON.parse(e.dataTransfer.getData('text/plain'));
    const newDroppedItems = [...droppedItems];
    const updatedItems = items.filter((item) => item.id !== droppedItem.id);
    const replacedItem = newDroppedItems[index];
    if (replacedItem) {
      if (replacedItem.id !== droppedItem.id) {
        updatedItems.push(replacedItem);
        setDroppedIntoIndex(index)
        setIntoEmpty(false)
      }
    } else {
      setIntoEmpty(false)
    }
    newDroppedItems[index] = droppedItem;
    
    if (droppedItem && !newDroppedItems[index]) {
      setFilledGaps(filledGaps + 1); 
    }
    setItems(updatedItems);
    setDroppedItems(newDroppedItems);
    setDraggedOverIndex(null);
  };


  const handleDragOver = (e, index) => {
    if (isDraggable) {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    setDraggedOverIndex(index); // Mettre à jour l'indice du carré survolé
  };

  const handleDragLeave = (index) => {
    if (index === draggedOverIndex) {
      setDraggedOverIndex(null); // Réinitialiser l'indice du carré survolé seulement s'il est en train de quitter le carré
    }
  };
  const handleDragEnd = (index) => {
    const newDroppedItems = [...droppedItems];
    const removedItem = newDroppedItems[index];
    if (removedItem && intoEmpty === false) {
      setIntoEmpty(true)
      newDroppedItems[index] = null;
      setDroppedItems(newDroppedItems);
    } else {
      newDroppedItems[index] = null;
      setDroppedItems(newDroppedItems);
      // Si l'élément est déposé à l'extérieur, droppedIntoIndex est null
      const updatedItems = [...items];
      if (removedItem) {
        updatedItems.push(removedItem);
      }
      setItems(updatedItems);
    }
    if (droppedIntoIndex) {
      setDroppedIntoIndex(null);
    }
  };


  const renderTextWithGaps = () => {
    const parts = text.split(/([[[0-9]+]])/);
    return parts.map((part, index) => {
      if (!part.startsWith("[[")) {
        return <span key={`text-${index}`}>{part}</span>;
      }
      const gapIndex = parseInt(part.slice(2, -2));
      const droppedItem = droppedItems[gapIndex - 1];
      const key = `gap-${gapIndex}-${index}`;
      return (
        <div
          key={key}
          className="drop-target"
          onDrop={(e) => handleDrop(e, gapIndex - 1)}
          onDragOver={(e) => handleDragOver(e, gapIndex - 1)}
          onDragLeave={() => handleDragLeave(gapIndex - 1)}
        >
          {droppedItem && (
            <div className="drop-zone" onDragEnd={() => handleDragEnd(gapIndex - 1)}>
              <div
                className="dropped-item"
                draggable
                onDragStart={(e) => handleDragStart(e, droppedItem, gapIndex - 1)}
              >
                {droppedItem.text}
              </div>
            </div>
          )}

        </div>
      );
    });
  };


  return (
    <div className="QuizzGaps">
      <div className='gaps-content'>
        <div className='gaps'>
          {renderTextWithGaps()}
        </div>
        <div class="ligne"></div>
        <div className="items">
          <div className='items-content'>
            {items.map((item) => (
              <div
                key={item.id}
                className="draggable-item"
                draggable
                onDragStart={(e) => handleDragStart(e, item, null)}
              >
                {item.text}
              </div>
            ))}

          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizzGaps;