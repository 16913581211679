import {useState, useEffect} from "react"
import {createPortal} from "react-dom"
import FrontRoutes from "./services/FrontRoutes"
import {VolumeMenu} from "./services/Volume"
import Header from "./components/general/Header"
import {Session} from "scg.common-library"
import {hasSecondaryBackground, isGameRoute} from "./services/RoutesHelper"
import i18n from "i18next"
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"
import Helper from "./services/Helper"
import SoundService from "./services/SoundService"
import {ToastContainer} from "react-toastify"
import {useKonamiCode} from "./hooks/secretHooks"
import "react-toastify/dist/ReactToastify.css"
import "react-tooltip/dist/react-tooltip.css"

/* ----- FontAwesome icons import ----- */
import {library} from "@fortawesome/fontawesome-svg-core"
import {fas} from "@fortawesome/free-solid-svg-icons"
import {far} from "@fortawesome/free-regular-svg-icons"
import {fab} from "@fortawesome/free-brands-svg-icons"

// context
import VolumeContext from "./context/VolumeContext"
import {useLocation} from "react-router-dom"
import {useCurrentUser} from "./hooks/customHooks"
import {UserProfileContext} from "./context/UserProfileContext"
import i18next from "i18next"
import {Loading} from "./components/general/Loading"

library.add(fas, far, fab)

export default function App() {
    const [translationLoading, setTranslationLoading] = useState(true)
    initializeTranslation().then(() => {
        setTranslationLoading(false)
    })

    return <>{translationLoading?<Loading />:isGameRoute(window.location.pathname) ? <GameApp/> : <MainApp/>}</>
}

function MainApp() {
    const user = Session.getSessionUser()
    return <section className="app-section">
        <header className="header">
            <Header isLogged={Session.isLoggedIn()} user={user}/>
        </header>
        <article className="content" onScroll={changeHeaderBackground}>
            <FrontRoutes/>
            <footer className="footer">
                <div className="footer-version">Version {process.env.REACT_APP_VERSION}</div>
            </footer>
        </article>
    </section>
}

function GameApp() {
    const defaultVolumes = SoundService.getDefaultVolumes()
    const [volumes, setVolumes] = useState(defaultVolumes)
    const [secondaryBg, setSecondaryBg] = useState(false)
    const location = useLocation()
    const [loading, user, setUser] = useCurrentUser()
    const userContext = {user, setUser, loading}
    const konami = useKonamiCode()

    useEffect(() => {
        setSecondaryBg(hasSecondaryBackground(location.pathname))
    }, [location.pathname])

    const volumeContext = {volumes, setVolumes}
    return <>
        {/*{isLoading && <LoadingOverlay />}*/}
        <UserProfileContext.Provider value={userContext}>
            <VolumeContext.Provider value={volumeContext}>
                <section className="game-section">
                    {secondaryBg &&
                        <div id="secondary-background"> {/* Used to display secondary background */}
                            <video autoPlay muted loop>
                                <source src="/img/secondary-background.mp4" type="video/mp4"/>
                            </video>
                        </div>
                    }

                    {/* TODO: TEMPORAIRE ! J'ai mis ce composant ici pour faire un test mais à terme il faudra le déplacer ailleurs je pense */}
                    <SecretCode trigger={konami} user={user}/>

                    <article className="content">
                        <FrontRoutes/>
                        <ToastContainer/>
                    </article>
                    <footer className="footer">
                        <div className="footer-version">Version {process.env.REACT_APP_VERSION}</div>
                        <VolumeMenu className="footer-volume-menu" position="right"/>
                    </footer>
                </section>
            </VolumeContext.Provider>
        </UserProfileContext.Provider>
    </>
}

function SecretCode({trigger}) {
    const [display, setDisplay] = useState(false)
    const [lastState, setLastState] = useState(false)

    //Todo: vérifier si l'utilisateur a déjà débloqué ou non cet Easter Egg et modifier le conportement en fonction

    if (!display && trigger && !lastState) {
        setDisplay(true)
        setLastState(false)
    }

    const onClose = () => {
        setDisplay(false)
        setLastState(true)
    }
    return display ?
        createPortal(<>
                <section id="modalParameter" className="modal-overlay">
                    <div className="modal-wrapper">
                        <div className="modal">
                            <div className="modal-header">
                                <h4 className="modal-title">Konami Code</h4>
                                <button type="button" className="modal-close-button"
                                        title={i18next.t("modalParameter.modalClose")} onClick={onClose}>
                                    <img src="/img/parametres/buttonClose.svg"
                                         alt={i18next.t("modalParameter.modalClose")}/>
                                </button>
                            </div>
                            <div className="modal-body center">
                                <h3>Bien joué ! Vous avez trouvé le logo secret</h3>
                                <img src="/img/other/testKonami.jpeg" title={"TEST"} alt={"TEST"} width={500}/>
                            </div>
                        </div>
                    </div>
                </section>
            </>,
            document.body
        ) : null
}

function changeHeaderBackground(event) {
    document.querySelector("header.header").style.backgroundColor = (event.target.scrollTop < 200) ? "transparent" : "var(--header-background)"
}


async function initializeTranslation() {

    const translations = await Helper.getLocalTranslations("FR")

    await i18n.use(I18nextBrowserLanguageDetector)
        .use(initReactI18next)
        .init({
            resources: {
                fr: {
                    translation: translations.FR
                },
                en: {
                    translation: translations.EN
                }
            },
            fallbackLng: "fr"
        })
}