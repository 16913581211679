import React, { useState, useEffect} from "react"
import _ from "lodash"
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next"
import {routes as Routing} from "../../services/RoutesHelper"
import {BackButton, NextButton} from "../general/Button";
import {Api, Session, Constants} from "scg.common-library"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import UserProfile from "../general/UserProfile";
import PageTitle from "../general/PageTtitle";
import './quizProgression.css'

const createQuizSteps = (quizCount) => {
    let steps = []
    let index = 0
    let base = _.floor( (quizCount / 10) )
    let min = base < 1 ? 0 : base*10
    if( quizCount !==0 && quizCount % 10 === 0 ){
        min = min-10
    }
    let max = min + 10
    for( min; min <= max; min++){
        steps.push( { index : index, value : min } )
        index++
    }
    return steps

}

const matchCurrentStep = (steps, match) => {
    let currentStep = steps.filter((step) => step.value === match)
    if( currentStep.length ){
        return currentStep[0]
    }
    return null
}

const QuizLoading = () => {
    return (
        <div className="wrap-quiz-loading">
            <FontAwesomeIcon fontSize="4rem" icon="fas fa-gear loading-icon" spin/>
        </div>
    )
}

const QuizItem = ({ index, current }) => {
    const {t} = useTranslation()
    const renderContent = () => {
        if( index === 5 || index === 10 ){
            let gift_img = ""
            const gift_txt = index === 5 ? "X2" : "X4"
            if( index === 5 ){
                gift_img = current?.index > 5 ? "img/progression/point-inactif.svg" : "img/progression/gift-ico-1.svg"
            }
            if( index === 10 ){
                gift_img = current?.index > 10 ? "img/progression/gift-inactif.svg" : "img/progression/gift-ico-2.svg"
            }
            return (
                <>
                    <img src={`${process.env.PUBLIC_URL}/${gift_img}`} alt="" />
                    <span className="gift-value">{gift_txt}</span>
                    <p className="gift-popover">
                        <HourglassBottomIcon color="#fff" />
                        <span className="popover-text">{t("game.quiz_progress.gift_info")}</span>
                    </p>
                </>

            )
        }
        return index
    }
    if( index > 10 ){
        return null
    }
    return (
        <span className={`${ index <= current?.index ? 'quiz-item active' : 'quiz-item' }  quiz-index-${index} ${ index % 5 === 0 ? 'quiz-bonus' : 'quiz-default' }`}>
            <span className="inner-quiz">{ renderContent() }</span>
            { index === 5 && <span className="round"/> }
        </span>
    )
}

const QuizProgress = ({ steps, current, quizCount = 0 }) => {
    if( !steps.length ){
        return null
    }
    return (
        <div className={`wrap-progress current-quiz-${current?.index}`} data-quiz-index={current?.index} data-quiz={quizCount}>
            { steps.map( (value) => <QuizItem key={value.index} current={current} index={value.index+1} /> ) }
        </div>
    )
}

const QuizNotification = ({ current, userData= {}}) => {
    const { t } = useTranslation()
    const giftPos = current?.index < 5 ? 5 : 10
    let message = t("game.quiz_progress.target", {giftPos: giftPos-current?.index } )
    if( userData?.user.quizCount % 5 === 0 ){
        message = t("game.quiz_progress.gift", {gift: 'Récompense'})
    }
    return (
        <div className="quiz-notification">
            <h2 className="notif-name">{message}</h2>
        </div>
    )
}

const Progression = ({ isLoading, userData= {} }) => {
    const quizCount = userData?.user?.quizCount
    const steps = createQuizSteps(quizCount)
    const current = matchCurrentStep(steps, quizCount)
    // hooks
    const location = useLocation()
    const { t } = useTranslation()

    return (
        <>
            <div className="quiz-progression-bar">
                <PageTitle>{t("game.quiz_progress.title")}</PageTitle>
                <p className="quiz-info">{t("game.quiz_progress.sub")}</p>
                {
                    isLoading
                        ?
                        <QuizLoading/>
                        :
                        <>
                            <QuizProgress steps={steps} current={current} quizCount={quizCount}/>
                            <QuizNotification current={current} userData={userData}/>
                        </>
                }
            </div>
            {!isLoading && <div className="quiz-buttons">
                <BackButton to={Routing.game.path} title={("game.quiz_progress.back")}/>
                <NextButton
                    to={Routing.quiz_info.path}
                    title={t("game.quiz_progress.details")}
                    state={{
                        "questionNumber": 5,
                        "quizTimeLeft": location?.state?.quizTimeLeft,
                        "fromNormalStep": location?.state?.fromNormalStep,
                        "delay": userData?.user?.society.parameter.timeLimitQuestion,
                    }}
                />
            </div>}
        </>
    )
}

function QuizProgression() {
    // states
    const [isLoading, setIsLoading] = useState(true)
    const [userData, setUserData] = useState({})
    // hooks
    const location = useLocation()
    const navigate = useNavigate()

    // onMount
    useEffect(() => {
        Api.user.getUser(Session.getSessionUser().id, true, Session.getJwtAndRefreshToken())
            .then((response) => {
                if (response.status === Constants.HTTP_OK) {
                    setUserData(response.data)
                }
            } ).finally(() => setIsLoading(false))
    }, []);

    // onDidUpdate
    useEffect( () =>{
        if( location ){
            if (!location.state?.fromNormalStep || location.state?.quizTimeLeft > 0){
                navigate(Routing.game.path)
            }
        }
    }, [location, navigate] )

    return (
        <div className='quiz-progression-container'>
            <UserProfile />
            <Progression
                isLoading={isLoading}
                userData={userData}
            />
        </div>
    )
}

export  default QuizProgression