import React from "react"
import "./profileTitle.css"

const ProfileTitle = ({ titleObj, active = false, style }) => {
    return (
        <>
            {titleObj?
                (<div
                    className={`profile-title ${active ? "active" : ""}`}
                >
                    {titleObj.name}
                </div>):<></>
            }
        </>
    )
}

export default ProfileTitle