import {BackButton} from "../general/Button"
import {routes as Routing} from "../../services/RoutesHelper"
import {useTranslation} from "react-i18next"
import "./scoreboard.css"

export default function ScorboardPage() {
    const {t} = useTranslation()

    return <div className="scoreboards">
        <h1>Page des CLASSEMENTS</h1>
        <BackButton to={Routing.game.path} title={t("game.changelog.back")} className="changelog-backButton"/>
        <img src="/img/other/scoreboard.png" className="temporary-img" title={"TEST"} alt={"TEST"}/>
    </div>
}