import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import HomePage from '../components/home/HomePage';
import GameHomePage from '../components/game/GameHomePage';
import { LoginPage, LogoutPage } from '../components/login/LoginPage';
import ParametersPage from '../components/parameters/ParametersPage';
import QuizHomePage from '../components/quiz/QuizHomePage';
import QuizProgression from '../components/quiz/QuizProgression';
import QuizInformation from '../components/quiz/QuizInformation';
import CorrectionPage from '../components/quiz/CorrectionPage';
import ChallengeHomePage from '../components/challenges/ChallengeHomePage';
import ChallengeInformation from '../components/challenges/ChallengeInformation';
import ShopPage from '../components/shop/ShopPage';
import BadgePage from '../components/badges/BadgePage';
import ScoreboardPage from '../components/scoreboard/ScoreboardPage';
import ActivationPage from '../components/login/ActivationPage';
import ErrorPage from '../components/general/Error/ErrorPage';
import UserProfile from '../components/game/UserProfile/UserProfile';
import InformationCenter from '../components/game/InformationCenter';
import {routes, routes as Routing} from './RoutesHelper';
import { Session, Roles, Constants } from 'scg.common-library';
import i18n from 'i18next';
import CorrectionDetailsPage from '../components/quiz/CorrectionDetailsPage';
import HistoryPage from '../components/profile/history/HistoryPage';
import ChangelogPage from '../components/changelog/ChangelogPage';
import ProfileGeneral from '../components/profile/general/ProfileGeneral';
import ProfileAvatar from '../components/profile/avatar/ProfileAvatar';
import ProfileStatistics from '../components/profile/statistics/ProfileStatistics';
import ProfileGInfos from '../components/profile/general/information/ProfileGInfos';
import ProfileGIcons from '../components/profile/general/icone/ProfileGIcons';
import ProfileGFrames from '../components/profile/general/frame/ProfileGFrames';
import ProfileGTitles from '../components/profile/general/title/ProfileGTitles';
import MentionsLegalesPage from '../components/mentions/MentionsLegalesPage';
import CreditsPage from '../components/credits/CreditsPage';
import PolitiqueConfiPage from '../components/politique/PolitiqueConfiPage';
import {useCurrentUser} from "../hooks/customHooks";
import Helper from "./Helper";
import {LoadingOverlay} from "../components/general/Loading";

function getQuizIdFromLocation(location) {
  const locationSplit = location.pathname.split('/');
  return locationSplit[locationSplit.length - 2];
}

export default function FrontRoutes() {
  const location = useLocation();

  return (
    <Routes>
      <Route path={Routing.app_home.path} element={<SecureRoute minRole={null} route={Routing.app_home} component={HomePage} />} />
      <Route path={Routing.app_login.path} element={<SecureRoute minRole={null} route={Routing.app_login} component={LoginPage} />} />
      <Route path={Routing.app_logout.path} element={<SecureRoute minRole={null} route={Routing.app_logout} component={LogoutPage} />} />
      <Route path={Routing.app_activate.path} element={<SecureRoute minRole={null} route={Routing.app_activate} component={ActivationPage} />} />
      <Route path={Routing.game.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.game} component={GameHomePage} />} />
      <Route path={Routing.profile.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.profile} component={UserProfile} />}>
        <Route path={Routing.profile_general.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.profile_general} component={ProfileGeneral} />}>
          <Route path={Routing.profile_general_infos.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.profile_general_infos} component={ProfileGInfos} />} end />
          <Route path={Routing.profile_general_icons.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.profile_general_icons} component={ProfileGIcons} />} />
          <Route path={Routing.profile_general_frames.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.profile_general_frames} component={ProfileGFrames} />} />
          <Route path={Routing.profile_general_titles.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.profile_general_titles} component={ProfileGTitles} />} />
        </Route>
        <Route path={Routing.profile_history.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.profile_history} component={HistoryPage} />} />
        <Route path={Routing.profile_statistic.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.profile_statistic} component={ProfileStatistics} />} />
          <Route path={Routing.profile_avatar.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.profile_avatar} component={ProfileAvatar} />} />
      </Route>

      <Route path={Routing.information_center.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.information_center} component={InformationCenter} />} />
      <Route path={Routing.parameters.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.parameters} component={ParametersPage} />} /> {/* // TODO A supprimer quand c'est validé */}
      <Route path={Routing.quiz.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.quiz} component={QuizHomePage} parametersObject={location.state} />} />
      <Route path={Routing.quiz_correction.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.quiz_correction} component={CorrectionPage} quizId={getQuizIdFromLocation(location)} data={location.state} />} />
      <Route path={Routing.quiz_correction_details.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.quiz_correction_details} component={CorrectionDetailsPage} quizId={getQuizIdFromLocation(location)} data={location.state} />} />
      <Route path={Routing.quiz_progress.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.quiz_progress} component={QuizProgression} />} />
      <Route path={Routing.quiz_info.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.quiz_info} component={QuizInformation} />} />
      <Route path={Routing.challenge.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.challenge} component={ChallengeHomePage} />} />
      <Route path={Routing.challenge_info.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.challenge_info} component={ChallengeInformation} />} />
      <Route path={Routing.shop.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.shop} component={ShopPage} />} />
      <Route path={Routing.badge.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.badge} component={BadgePage} />} />
      <Route path={Routing.scoreboard.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.scoreboard} component={ScoreboardPage} />} />
      <Route path={Routing.history.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.history} component={HistoryPage} />} />
      <Route path={Routing.changelog.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.changelog} component={ChangelogPage} />} />
      <Route path={Routing.mentions.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.mentions} component={MentionsLegalesPage} />} />
      <Route path={Routing.credits.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.credits} component={CreditsPage} />} />
      <Route path={Routing.politique.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.politique} component={PolitiqueConfiPage} />} />


      <Route path="*" element={<ErrorPage code="404" title={i18n.t('error.notFound.title')} message={i18n.t('error.notFound.message')} />} />
    </Routes>
  );
}

function SecureRoute({ component: Component, minRole, route, ...props }) {
  if (minRole !== null) {
    const user = Session.getUser();

    if (!Session.isLoggedIn() || user === null) {
      return <Navigate to={Routing.app_login.path} replace />;
    }

    if (minRole != null && !Roles.isGranted(user?.roles[0], minRole)) {
      return <ErrorPage code="403" title={i18n.t('error.accesDenied.title')} message={i18n.t('error.accesDenied.message')} />;
    }

    //Check licence user society
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const location = useLocation()
      const checkRoute = [
          routes.scoreboard.path,
          routes.information_center.path,
          routes.challenge.path,
          routes.quiz.path,
          routes.quiz_progress.path,
          routes.quiz_info.path,
          routes.quiz_correction.path
      ]
      if (checkRoute.includes(location.pathname)) {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [loading, userDetail] = useCurrentUser()
          const isActiveLicence = Helper.isActivateLicence(userDetail)
          if( loading ){
              return <LoadingOverlay />
          }
          if (!isActiveLicence) {
              return <Navigate to={Routing.game.path} />;
          }
      }
  }

  document.title = i18n.t(route.title) + ' - ' + Constants.DOCUMENT_TITLE_FRONTOFFICE;
  return <Component {...props} />;
}
