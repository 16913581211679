import React from "react"
import "../web/style/volume.css"
import SoundService from "./SoundService"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import i18n from "i18next"
import VolumeContext from "../context/VolumeContext"

class VolumeSet extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            percent: .8,
            display: false,
            muted: false
        }
        this.handleVolumeChange = this.handleVolumeChange.bind(this)
        this.handleMuteClick = this.handleMuteClick.bind(this)
    }

    handleVolumeChange(e) {
        const value = e.target.value ?? 0.0
        this.setState(() => ({
            percent: value
        }))
        SoundService.setGlobalVolume(this.state.percent)
    }

    handleMuteClick() {
        const volume = this.state.muted ? 0.5 : 0.0 // set a default volume value on mute/unmute
        this.setState((prevState) => ({
            muted: !prevState.muted,
            percent: volume
        }), () => {
            SoundService.muteGlobalVolume(this.state.muted)
        })
    }

    displaySlider(visible = true) {
        this.setState(() => ({
            display: visible
        }))
    }

    render() {
        let {className, position} = this.props
        className = className ?? ""
        position = position ?? "center"

        const state = this.state.percent === 0.0 ? "unmute" : "mute"
        const title = `Click to ${state}`

        let icon = "fa-volume-high"
        if (parseFloat(this.state.percent) === 0.0) {
            icon = "fa-volume-xmark"
        } else if (parseFloat(this.state.percent) > 0.0 && parseFloat(this.state.percent) <= 0.2) {
            icon = "fa-volume-off"
        } else if (parseFloat(this.state.percent) > 0.2 && parseFloat(this.state.percent) <= 0.6) {
            icon = "fa-volume-low"
        }

        return <div className={`volume-slider slider-icon-${position} ${className}`}
                    onMouseEnter={() => {
                        this.displaySlider()
                    }}
                    onMouseLeave={() => {
                        this.displaySlider(false)
                    }}>
            <FontAwesomeIcon icon={`fa-solid ${icon}`} title={title} onClick={this.handleMuteClick}/>
            <div className={`slider-container ${!this.state.display ? "hidden" : ""}`}>
                <input type="range" step="0.01" min="0"
                       max="1" value={this.state.percent} onChange={this.handleVolumeChange}
                       title={this.state.percent}/>
            </div>
        </div>
    }
}

class VolumeMenu extends React.Component {

    static contextType = VolumeContext

    constructor(props) {
        super(props)
        this.state = {
            display: false,
            globalMuted: false
        }

        this.handleGeneralChange = this.handleGeneralChange.bind(this)
        this.handleMusicChange = this.handleMusicChange.bind(this)
        this.handleSoundChange = this.handleSoundChange.bind(this)
        this.handleDialogChange = this.handleDialogChange.bind(this)
    }


    handleGeneralChange(e) {
        const value = e.target.value ?? 0.0
        const { volumes, setVolumes } = this.context
        SoundService.setDefaultVolumes({general: value})
        SoundService.setGlobalVolume(value)
        setVolumes({ ...volumes, ...{ general : value } })

    }

    handleMusicChange(e) {
        const value = e.target.value ?? 0.0
        const { volumes, setVolumes } = this.context
        SoundService.setDefaultVolumes({music: value})
        SoundService.updateMusicVolume(value)
        setVolumes({ ...volumes, ...{ music : value } })
    }

    handleSoundChange(e) {
        const value = e.target.value ?? 0.0
        const { volumes, setVolumes } = this.context
        SoundService.setDefaultVolumes({sounds: value})
        setVolumes({ ...volumes, ...{ sounds : value } })
    }

    handleDialogChange(e) {
        const value = e.target.value ?? 0.0
        const { volumes, setVolumes } = this.context
        SoundService.setDefaultVolumes({dialog: this.state.dialog})
        setVolumes({ ...volumes, ...{ dialog : value } })
    }

    displayMenu(visible = true) {
        const { volumes } = this.context
        this.setState({
            display: visible,
            general: volumes.general,
            music: volumes.music,
            dialog: volumes.dialog,
            sounds: volumes.sounds,
        })
    }

    render() {
        let {className, position} = this.props
        className = className ?? ""
        position = position ?? "center"

        return <div className={`volume-slider-multiple sliders-icon-${position} ${className}`}
                    title={i18n.t("volume.manager.open")}>
            <FontAwesomeIcon icon="fa-solid fa-volume-high"
                             onClick={() => {
                                 this.displayMenu(!this.state.display)
                             }}/>
            <div className={"sliders-container " + (!this.state.display ? "hidden" : "")}>
                <FontAwesomeIcon icon="fa-solid fa-xmark" className="volume-slider-multiple-close"
                                 title={i18n.t("volume.manager.close")}
                                 onClick={() => {
                                     this.displayMenu(false)
                                 }}/>
                <div className="slider-container">
                    <div className="slider-item">
                        <span>{i18n.t("volume.general")}</span>
                        <div>
                            <input type="range" step="0.01" min="0"
                                   max="1" value={this.context.volumes.general} onChange={this.handleGeneralChange}
                                   title={this.context.volumes.general}/>
                            <span>{(this.context.volumes.general * 100).toFixed(0)}% </span>
                        </div>
                    </div>
                    <div className="slider-item">
                        <span>{i18n.t("volume.music")}</span>
                        <div>
                            <input type="range" step="0.01" min="0"
                                   max="1" value={this.context.volumes.music} onChange={this.handleMusicChange}
                                   title={this.context.volumes.music}/>
                            <span>{(this.context.volumes.music * 100).toFixed(0)}% </span>
                        </div>
                    </div>
                    <div className="slider-item">
                        <span>{i18n.t("volume.sounds")}</span>
                        <div>
                            <input type="range" step="0.01" min="0"
                                   max="1" value={this.context.volumes.sounds} onChange={this.handleSoundChange}
                                   title={this.context.volumes.sounds}/>
                            <span>{(this.context.volumes.sounds * 100).toFixed(0)}% </span>
                        </div>
                    </div>
                    <div className="slider-item">
                        <span>{i18n.t("volume.dialogs")}</span>
                        <div>
                            <input type="range" step="0.01" min="0"
                                   max="1" value={this.context.volumes.dialog} onChange={this.handleDialogChange}
                                   title={this.context.volumes.dialog}/>
                            <span>{(this.context.volumes.dialog * 100).toFixed(0)}% </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export
{
    VolumeSet,
    VolumeMenu
}