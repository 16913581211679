import Phaser from 'phaser';
import { ChallengePreloader } from './scenes/ChallengePreloader';
import { NavigationChallenge } from './scenes/NavigationChallenge';
import { DialogueChallenge } from './scenes/DialogueChallenge';
import { ActionChallenge } from './scenes/ActionChallenge';
import { GameOver } from './scenes/GameOver';
import { Success } from './scenes/Success';

const StartGame = (parent, challengeDatas) => {
    const config = {
        type: Phaser.AUTO,
        width: '90%',
        height: '60%',
        parent: parent,
        backgroundColor: 0x000000,
        transparent: true,
        scene: [
            ChallengePreloader,
            ActionChallenge,
            NavigationChallenge,
            DialogueChallenge,
            GameOver,
            Success
        ],
    };

    const game = new Phaser.Game(config);

    game.scene.start('ChallengePreloader', { challengeDatas: challengeDatas });

    return game;
};

export default StartGame;
