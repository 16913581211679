import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useTranslation} from "react-i18next"
import './loading.css'

export function LoadingOverlay(){
    const {t} = useTranslation()
    return <div className="loading-overlay">
        <div className="overlay-content">
            <div>{t("game.loading")}</div>
            <span className="loader"/>
        </div>

        <div className="overlay-brand">
            <img src="/img/Nextaura2.png" alt="Nextaura brand logo"/>
        </div>
    </div>
}


export function Loading(){
    return <div className="loading">
        <div className="loading-content">
            <FontAwesomeIcon icon="fas fa-gear loading-icon" spin/>
        </div>
    </div>
}
