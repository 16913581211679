import React from "react"
import PropTypes from 'prop-types'
import i18n from "i18next"

function UserParameters({ onClick }) {
    const handleClick = () => {
        if (typeof (onClick) === 'function') {
            onClick()
        }
    }
    return <div className="user-parameters">
            <div className="content" onClick={handleClick}>
                <div className="user-parameterText">{i18n.t("game.home.parameters")}</div>
                <img src="/img/icon/ionic-md-settings.svg" alt="parameter-icon" width={40} />
            </div>
    </div>

}

UserParameters.propTypes = {
    onClick: PropTypes.func.isRequired
}

export {
    UserParameters
}