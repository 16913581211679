import "./challenge.css";
import { NavLink } from "react-router-dom";
import { routes as Routing } from "../../services/RoutesHelper";
import { useEffect, useRef, useState } from "react";
import { EventBus } from "./services/EventBus";
import { PhaserGame } from "./PhaserGame";
import { useTranslation } from "react-i18next";
import { dataLoader } from "./services/dataloader";

export default function ChallengeHomePage() {
    const [dialogue, setDialogue] = useState('');
    const challengeDatasRef = useRef({});
    const [challengeDatas, setChallengeDatas] = useState({});
    const [dataReady, setDataReady] = useState(false); // New state to check if data is ready
    const currentStoryRef = useRef({});
    const [currentStory, setCurrentStory] = useState(currentStoryRef.current);
    const phaserRef = useRef();
    const currentTimerRef = useRef(null);
    const [buttonsDisabled, setButtonsDisabled] = useState(false); // State to track if buttons are disabled

    const { t } = useTranslation();

    // Import du fichier pour le challenge
    useEffect(() => {
        fetch('/challenges/challenge1/challenge1.json')
            .then(response => response.json())
            .then(data => {
                challengeDatasRef.current = data;
                setChallengeDatas(data);
                dataLoader.setDatas(data)
                setDataReady(true); // Set data as ready
            })
            .catch(error => console.error('Error loading data:', error));
        
        return () => {
            EventBus.removeAllListeners('data-loaded');
        };
    }, []);

    // Gestion des événts phaser et affichage des dialogues
    useEffect(() => {
        EventBus.on('setStory', (storynum) => {
            if (challengeDatasRef.current && challengeDatasRef.current.story) {
                const newStory = { ...challengeDatasRef.current.story[storynum], showButtons: false };
                currentStoryRef.current = newStory;
                setCurrentStory(currentStoryRef.current);
            }
        });

        EventBus.on('show-dialogue', (text) => {
            setDialogue(text);
            document.getElementById('dialogue').style.display = 'block';
            if (currentTimerRef.current) {
                currentTimerRef.current(); // Clear the previous timer
            }
            // currentTimerRef.current = typeWriterEffect(text, setDialogue); // Use the typewriter effect function
        });

        EventBus.on('show-buttons', () => {
            if (currentStoryRef.current.type === "action") {
                currentStoryRef.current.showButtons = true;
                setCurrentStory({ ...currentStoryRef.current });
            }
        });

        EventBus.on('hide-dialogue', () => {
            setDialogue('');
            document.getElementById('dialogue').style.display = 'none';
            if (currentTimerRef.current) {
                currentTimerRef.current(); // Clear the timer when hiding the dialogue
            }
        });

        return () => {
            EventBus.removeAllListeners('show-dialogue');
            EventBus.removeAllListeners('hide-dialogue');
            EventBus.removeAllListeners('setStory');
        };
    }, [challengeDatas]);

    // eslint-disable-next-line no-unused-vars
    const currentScene = (scene) => {
    }

    const handleButtonClick = (action) => {
        phaserRef.current.scene.handleClick(action);
        setButtonsDisabled(true); // Disable buttons after one is clicked
    };

    return (
        <span className="challenge-head">
            <NavLink to={Routing.game.path} style={{textDecoration: "none", paddingTop: 20}}>
                <div className="challenge-btn-back">
                    <div className="txt-back">{t("profile.back")}</div>
                    <div className="ligne-top"></div>
                </div>
            </NavLink>

            <div id="app">
                <div className='game'>
                    <div id="gamediv" style={{ display: 'flex', alignItems: 'center' }}>
                        <div id="challengeSidebar">
                            <div className='section1'>
                                <div className="title" style={{ marginBottom: '2rem' }}>Action</div>
                                {
                                    (currentStory && currentStory.buttons && currentStory.showButtons) && currentStory.buttons.map((button, index) => {
                                        return <button key={index} className="button" onClick={() => handleButtonClick(button.trigger)} disabled={buttonsDisabled}>{button.name}</button>
                                    })
                                }
                            </div>
                            <div className="section2">
                                {/* <button className="button" onClick={() => {}}>Magasin</button> */}
                                <h2>Lorem</h2>
                            </div>
                            <div className="section3">
                                {/* <button className="button" onClick={() => { }}>Magasin</button>
                                <button className="button" onClick={() => {}}>Magasin</button> */}
                                <h2>Lorem</h2>
                                <h2>Lorem</h2>
                            </div>
                        </div>
                        
                        <div className="screengame">
                            <div className='topbar'>
                                <div>
                                    <div className='titlegroup'>
                                        <span className='title'>Objectif </span>
                                        <span className="sub">{challengeDatas.Description}</span>
                                    </div>
                                    <div className='titlegroup' style={{ borderTop: '1px solid white', paddingTop: '10px' }}>
                                        <span className='title'>Environnement </span>
                                        <span className="sub">{currentStory.title}</span>
                                    </div>
                                </div>
                                <div className='topbar-right'>
                                    <span className="sub">Timer</span>
                                    <span className="sub">Money</span>
                                </div>
                            </div>  
                            <div className='phaserscreen'>
                                {dataReady && (
                                    <PhaserGame ref={phaserRef} currentActiveScene={currentScene} challengeDatas={challengeDatas} />
                                )}
                            </div>

                            <div id="dialogue" className="dialogue">
                               {dialogue}
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </span>
    )
}
