import { EventBus } from './EventBus';
import Phaser from "phaser";

export function changeScene(currentScene, sceneKey, props = {}) {
    hideDialogue();
    currentScene.scene.start(sceneKey, { ...props });
}

export function stopScene(currentScene, sceneKey) {
    if (sceneKey) {
        currentScene.scene.stop(sceneKey);
    } else {
        currentScene.scene.stop();
    }
}

export function showDialogue(text) {
    EventBus.emit('show-dialogue', text);
}
export function hideDialogue() {
    EventBus.emit('hide-dialogue');
}
export function typeWriterEffect(text, setTextCallback, speed = 15) {
    let i = 0;
    let timerId;

    function typeWriter() {
        if (i <= text.length) {
            setTextCallback(text.substring(0, i));
            i++;
            timerId = setTimeout(typeWriter, speed);
        }
    }
    typeWriter();

    return () => clearTimeout(timerId); // Return a function to clear the timer
}

export function showButtons() {
    EventBus.emit('show-buttons');
}


export function createButton(scene, text, x, y, maxWidth) {
    // const fontSize = Math.min(20, maxWidth * 0.1); // Responsive font size
    const fontSize = 16

    const button = scene.add.text(x, y, text, {
        fontSize: `${fontSize}px`, // Responsive font size
        backgroundColor: '#ffcc00', // Button color
        color: '#000000',
        wordWrap: { width: maxWidth - (maxWidth * 0.1) },
        padding: {
            left: 20,
            right: 20,
            top: 10,
            bottom: 10
        },
        border: {
            color: '#000000', // Border color
            width: 2 // Border width
        },
        align: 'center',
        // fixedWidth: maxWidth,
        
    }).setInteractive();

    button.on('pointerover', () => {
        button.setStyle({ backgroundColor: '#ff9900' }); // Hover color
    });

    button.on('pointerout', () => {
        button.setStyle({ backgroundColor: '#ffcc00' }); // Original color
    });


    return button;
}
/**
 * 
 * @param {*} scene - phaser scene
 * @param {*} sprite - phaser sprite
 * @param {*} clickZones - [{coords: [], trigger: ''}]
 * @param {*} debug - boolean - if true, draw the polygons
 */
export function interactivePolygons(scene, sprite, clickZones, debug = false) {
    clickZones.forEach(shape => {
        // Adjust the coordinates according to the sprite's position and scale
        const adjustedCoords = shape.coords.map((coord, index) => {
            if (index % 2 === 0) {
                // Adjust x coordinate
                return sprite.x + (coord - sprite.displayOriginX) * sprite.scaleX;
            } else {
                // Adjust y coordinate
                return sprite.y + (coord - sprite.displayOriginY) * sprite.scaleY;
            }
        });

        const polygon = new Phaser.Geom.Polygon(adjustedCoords);

        const zone = scene.add.zone(0, 0, scene.sys.canvas.width, scene.sys.canvas.height).setOrigin(0, 0);
        zone.setInteractive(polygon, Phaser.Geom.Polygon.Contains)
            .on("pointerdown", () => {
                scene.handleClick(shape.trigger);
            })
            .on("pointerover", () => {
                scene.input.manager.canvas.style.cursor = 'pointer';
            })
            .on("pointerout", () => {
                scene.input.manager.canvas.style.cursor = 'default';
            });

        if (!debug) return;

        // Draw the polygon if debug mode is enabled 
        const graphics = scene.add.graphics();

        graphics.lineStyle(2, shape?.debugColor? shape?.debugColor : 0x00aa00);
        graphics.beginPath();
        graphics.moveTo(adjustedCoords[0], adjustedCoords[1]);

        for (let i = 2; i < adjustedCoords.length; i += 2) {
            graphics.lineTo(adjustedCoords[i], adjustedCoords[i + 1]);
        }

        graphics.closePath();
        graphics.strokePath();
    });
}
